import { FC, MouseEventHandler, TouchEventHandler, useCallback, useLayoutEffect, useRef, useState } from 'react'
import Style from '../../styles/common/RecallResizeWrapper.module.sass'
import classNames from 'classnames'

export interface IRecallResizeWrapperProps {
  firstComponent: JSX.Element | React.ReactNode
  secondComponent: JSX.Element | React.ReactNode
  height: number
  cssClass?: string
}

const RecallResizeWrapper: FC<IRecallResizeWrapperProps> = (props) => {
  const { firstComponent, secondComponent, height, cssClass } = props

  const [currentHeight, setCurrentHeight] = useState(height)
  const [isResizing, setIsResizing] = useState(false)
  const startY = useRef(0)

  const stopResizing = () => setIsResizing(true)

  useLayoutEffect(() => setCurrentHeight(height), [height])

  const onMouseMoveHandler: MouseEventHandler<HTMLDivElement> = (e) => handleMouseMove(e)
  const onTouchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => handleMouseMove(e.touches[0])

  const handleMouseMove = useCallback(
    (event: React.Touch | React.MouseEvent) => {
      if (!isResizing) return

      const hasMovedY = event.clientY - startY.current
      setCurrentHeight((prevHeight) => prevHeight + hasMovedY)
      startY.current = event.clientY
    },
    [isResizing],
  )

  const handleMouseUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setIsResizing(false)
      document.body.classList.remove(Style.noSelect)
      document.removeEventListener('mousemove', () => handleMouseMove(event))
      document.removeEventListener('mouseup', () => handleMouseUp(event))
    },
    [handleMouseMove],
  )

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsResizing(true)
    startY.current = event.clientY
    document.body.classList.add(Style.noSelect)
    document.addEventListener('mousemove', () => handleMouseMove(event))
    document.addEventListener('mouseup', () => handleMouseUp(event))
  }

  const renderResizeBar = () => {
    return (
      <div className={Style.resizeBar} onMouseDown={handleMouseDown} onTouchStart={onTouchMoveHandler} role='none'>
        <div className={Style.resizeIcon}>
          <div className={Style.line} />
          <div className={Style.line} />
        </div>
      </div>
    )
  }
  return (
    <div
      onMouseUp={handleMouseUp}
      onMouseMove={onMouseMoveHandler}
      onTouchMove={onTouchMoveHandler}
      onTouchEnd={stopResizing}
      onTouchCancel={stopResizing}
      role='none'
      className={classNames(Style.recallResizeWrapper, cssClass, { [Style.noneSelect]: isResizing })}
    >
      <div style={{ height: currentHeight }} className={Style.firstComponent}>
        {firstComponent}
      </div>
      {renderResizeBar()}
      <div className={Style.secondComponent}>{secondComponent}</div>
    </div>
  )
}

export default RecallResizeWrapper
