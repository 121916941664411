import { FC, useCallback, useEffect, useRef, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/WinwiseManageFilesModal.module.sass'
import {
  IHandyLinkModel,
  IWinwiseOpportunityDetailModel,
  IWinwiseOpportunityUploadedFile,
} from '../../models/IWinwiseOpportunity'
import WinwiseOpportunityFileUpload from '../winwise/WinwiseOpportunityFileUpload'

interface IManageFilesModalProps {
  open: boolean
  selectedOpportunity: IWinwiseOpportunityDetailModel
  onClose: (hasChange?: boolean) => void
  isOpportunityOwner?: boolean
  onFileChange: () => void
}

const WinwiseManageFilesModal: FC<IManageFilesModalProps> = (props) => {
  const { open, selectedOpportunity, isOpportunityOwner, onClose, onFileChange } = props
  const [opportunity, setOpportunity] = useState<IWinwiseOpportunityDetailModel>(selectedOpportunity)
  const fileUploadRef = useRef<{ updateOpportunity: () => void }>(null)
  const [currentHandyLinks, setCurrentHandyLinks] = useState<IHandyLinkModel[]>([])
  const [hasFileChange, setHasFileChange] = useState<boolean>(false)

  const getInitialHandyLinks = useCallback(
    () => (selectedOpportunity.handyLinks ? JSON.parse(selectedOpportunity.handyLinks) : []),
    [selectedOpportunity.handyLinks],
  )

  useEffect(() => {
    if (!open) return

    const initHandyLinks: string[] = getInitialHandyLinks()
    const handyLinks = initHandyLinks.map((m) => ({ value: m, id: '', error: '' }))
    setCurrentHandyLinks(handyLinks)
  }, [getInitialHandyLinks, open])

  const hasChange = () => {
    const initHandyLinks = getInitialHandyLinks()
    const handyLinksValue = currentHandyLinks.map((f) => f.value)
    const handyLinkChanged = Object.entries(initHandyLinks).toString() !== Object.entries(handyLinksValue).toString()

    return hasFileChange || handyLinkChanged
  }

  const onUploadFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity) => {
      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: [...newFiles, file] }
    })
    setHasFileChange(true)
    onFileChange()
  }

  const onRemoveFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity) => {
      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: newFiles }
    })
    setHasFileChange(true)
    onFileChange()
  }
  const onHandyLinkChange = (handyLinks: IHandyLinkModel[]) => {
    setCurrentHandyLinks(handyLinks)
  }

  const handleSave = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.updateOpportunity()
    }
    onClose(true)
  }

  const disabledDoneButton = currentHandyLinks.some((f) => f.error) || !hasChange()

  return (
    <RecallModal
      cssClass={Style.modalContainer}
      showActionButtons
      chatType={ChatTypeEnum.WINWISE}
      isShowing={open}
      onSave={handleSave}
      onClose={onClose}
      size={'large'}
      labelYes={'Done'}
      loadingYes={false}
      disabled={disabledDoneButton}
      hideNo={true}
    >
      <WinwiseOpportunityFileUpload
        opportunity={opportunity}
        disableTitle={true}
        goBack={() => null}
        onCreateNewThread={() => null}
        onOpportunityChange={setOpportunity}
        onUploadFileChange={onUploadFileChange}
        onRemoveFileChange={onRemoveFileChange}
        handyLinkChangeCallback={onHandyLinkChange}
        ref={fileUploadRef}
        readOnly={!isOpportunityOwner}
      />
    </RecallModal>
  )
}

export default WinwiseManageFilesModal
