import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatSession, LoadingAnswer, NomicSession, ShowExtendedInput } from '../stores/AppStore'
import { AppRoute } from '../enums/AppRouteConstants'
import { logAppAccess, openLink } from '../helpers/utils'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../config/msalAuth'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from './useShowFeature'

export const useChangeChat = () => {
  const setChatSession = useSetRecoilState(ChatSession)
  const setNomicSession = useSetRecoilState(NomicSession)

  const loadingAnswer = useRecoilValue(LoadingAnswer)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)
  const showSharepointPicker = useShowFeature(FeatureFlagEnum.ShowSharePointFilePicker)

  const { instance } = useMsal()

  const openRecallChat = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    setShowExtendedInput(true)
    logAppAccess('Recall Chat')
    location.hash = `#/${AppRoute.RECALL_CHAT}`
  }

  const openChatGPT = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Secure Chat GPT')
    location.hash = `#/${AppRoute.GPT_CHAT}`
  }

  const openWinWise = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('WinWise by clientSense')
    location.href = `#/${AppRoute.WINWISE}`
  }

  const openCode = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Code Assistant')
    location.href = `#/${AppRoute.CODE_CHAT}`
  }

  const openBamboo = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Bamboo Chat')
    location.href = `#/${AppRoute.BAMBOO_CHAT}`
  }

  const openCustomRecallChat = (id?: string) => {
    if (loadingAnswer || !id) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Knowledge Agent')
    location.href = `#/${AppRoute.CUSTOM_RECALL_APP_CHAT}/${id}`
  }

  const openCustomRecallApp = async () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Knowledge Agent')
    location.href = `#/${AppRoute.CUSTOM_RECALL_APP}`
    if (showSharepointPicker.enabled) {
      const account = instance.getActiveAccount()
      if (!account) await instance.loginRedirect(loginRequest)
    }
  }

  const openBHPStandards = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('BHPStandards')
    location.href = `#/${AppRoute.BHP_STANDARDS}`
  }

  const openRioTintoStandards = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('RioStandards')
    location.href = `#/${AppRoute.RIO_TINTO_STANDARDS}`
  }

  const openAusNetStandards = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('AusNetStandards')
    location.href = `#/${AppRoute.AUSNET_STANDARDS}`
  }

  const openPMAssistant = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('PM Assistant')
    location.href = `#/${AppRoute.PM_CHAT}`
  }

  const openClientSense = (url: string) => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Client Sense')
    openLink(url, true, false)
  }

  return {
    openRecallChat,
    openChatGPT,
    openWinWise,
    openCode,
    openBamboo,
    openCustomRecallApp,
    openCustomRecallChat,
    openBHPStandards,
    openRioTintoStandards,
    openAusNetStandards,
    openPMAssistant,
    openClientSense,
  }
}
