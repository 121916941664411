import { FC, useRef, useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Loader, Pill } from '@aurecon-creative-technologies/styleguide'

import { appInsightsInstance } from '../api/AppInsights'
import { fetchChatQuestions } from '../api/QuestionService'
import { ChatSession, ChatType, NotFound, ScrollChat } from '../stores/AppStore'
import Page from '../components/Page'
import ChatQuestion from '../components/ChatQuestion'
import ChatAnswer from '../components/ChatAnswer'
import ChatNotFound from '../components/ChatNotFound'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'

import Style from '../styles/Home.module.sass'

const ChatCode: FC = () => {
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [scrollChat, setScrollChat] = useRecoilState(ScrollChat)
  const setChatType = useSetRecoilState(ChatType)
  const showCodeAssistant = useShowFeature(FeatureFlagEnum.ShowCodeAssistantChat)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const { chatId } = useParams()

  useEffect(() => {
    setChatType(ChatTypeEnum.CODE)

    return () => setChatType(null)
  }, [setChatType])

  useEffect(() => {
    if (showCodeAssistant.enabled) return

    setChatType(null)
    location.hash = '#/'
  }, [showCodeAssistant, setChatType])

  const startScroll = useCallback(() => {
    setTimeout(() => {
      setScrollChat((s) => s + 1)
    }, 1000)
  }, [setScrollChat])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = chatId || ''
      const questions = await fetchChatQuestions(id, ChatTypeEnum.CODE)

      if (!questions?.data) {
        setLoading(false)
        setNotFound(true)
        setChatSession(null)
        return
      }

      setChatSession({
        chatId: id,
        questions: questions.data,
        type: ChatTypeEnum.CODE,
      })

      setLoading(false)
      setNotFound(false)

      startScroll()
    }

    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, setChatSession, setNotFound, setScrollChat, startScroll])

  useEffect(() => {
    if (!contentsRef.current) return

    contentsRef.current.scrollTo({ top: contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [scrollChat])

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Code Assistant' })

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle}>
        Code Assistant
        <br />
        <Pill colour={13} icon='science'>
          Experimental
        </Pill>
      </h1>
      {loading && <Loader label='Loading chat...' />}
      {!loading && notFound && <ChatNotFound />}
      {!loading &&
        chatSession?.questions.map((question) => {
          return (
            <div key={question.rowKey} id={`qa-holder-${question.rowKey}`}>
              <ChatQuestion question={question} />
              <ChatAnswer question={question} />
            </div>
          )
        })}
      <FileDownloadModal />
    </Page>
  )
}

export default ChatCode
