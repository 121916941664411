import { appInsightsInstance } from '../api/AppInsights'
import { LOCAL_CORS, MAX_FILE_NAME_LENGTH } from '../config/config'
import { format } from 'date-fns'
import { AppRoute } from '../enums/AppRouteConstants'
import pdfToText from 'react-pdftotext'
import { IScrollChatData } from '../models/IScrollChatArea'

export const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}

export const validateAlphaNumeric = (value: string): boolean => /^[a-zA-Z0-9\s]+$/.test(String(value))
export const validateAlphaNumericUnderscoreDash = (value: string): boolean => /^[a-zA-Z0-9-_]+$/.test(String(value))

export const validateSpecialCharacters = (value: string): boolean => !/[^A-Za-z0-9\s]/.test(value?.trim())

export const isAureconUser = (email: string): boolean => {
  if (!email?.includes('@')) return false
  return email.split('@')[1].toUpperCase() === 'AURECONGROUP.COM'
}

export const validateUrl = (url: string): boolean => {
  try {
    new URL(url)
  } catch (e) {
    console.info('Invalid url - validation failed.')
    return false
  }
  return true
}

export const getUniqueObjects = <T>(array: Array<T>, key: string): T[] => {
  return array.filter((obj, i, self) => self.findIndex((obj2) => obj2[key] === obj[key]) === i)
}

export const getInternalNameFromEmail = (email: string): string => {
  const parts = email.toLowerCase().split('@')
  const nameParts = parts[0].split('.')
  const name = nameParts.map((n) => n.slice(0, 1).toUpperCase() + n.slice(1))
  return name.join(' ')
}

export interface ISyncResult<T> {
  unchanged: Array<T>
  remove: Array<T>
  create: Array<T>
}

export const arraySync = <T>(arrayOne: Array<T>, arrayTwo: Array<T>, key: string): ISyncResult<T> => {
  const result: ISyncResult<T> = {
    unchanged: [],
    remove: [],
    create: [],
  }

  // Items to remove
  arrayOne.forEach((a1) => {
    let remove = true
    arrayTwo.forEach((a2) => {
      if (a1[key] === a2[key]) remove = false
    })

    if (remove) result.remove.push(a1)
    else result.unchanged.push(a1)
  })

  // Items to create
  arrayTwo.forEach((a2) => {
    let create = true
    arrayOne.forEach((a1) => {
      if (a1[key] === a2[key]) create = false
    })

    if (create) result.create.push(a2)
  })

  return result
}

export interface IDifferenceResult<T> {
  unchanged: Array<T>
  changed: Array<T>
}

export const findDifference = <T>(
  arrayOne: Array<T>,
  arrayTwo: Array<T>,
  uniqueKey: string,
  diffKey: string,
): IDifferenceResult<T> => {
  const result: IDifferenceResult<T> = {
    unchanged: [],
    changed: [],
  }

  for (const arrayOneValue of arrayOne) {
    const match = arrayTwo.find((element) => arrayOneValue[uniqueKey] === element[uniqueKey])
    if (match) {
      if (arrayOneValue[diffKey] === match[diffKey]) result.unchanged.push(arrayOneValue)
      else result.changed.push(match)
    } else result.unchanged.push(arrayOneValue)
  }

  return result
}

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value))
}

export const unixDateToString = (time: number): string => {
  const dateObject = new Date(Number(time))
  return dateObject.toLocaleString('en-AU', { year: 'numeric', month: 'numeric', day: 'numeric' })
}

export const unixDateTimeToString = (date: number | string | Date): string => {
  const dateObject = new Date(date)
  return dateObject.toLocaleString('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const truncateFileName = (name: string): string => {
  const temp = name.split('.')
  const filename = temp.slice(0, -1).join('.').slice(0, MAX_FILE_NAME_LENGTH)
  return `${filename}.${temp[temp.length - 1]}`
}

export const getReadableSize = (size: number | string): string => {
  let fileSizeIndex = 0
  let calculatedSize = Number(size)

  while (calculatedSize > 1024) {
    calculatedSize = calculatedSize / 1024
    fileSizeIndex++
  }

  return `${calculatedSize.toFixed(2)} ${fileSizes[fileSizeIndex]}`
}

const fileSizes = ['B', 'KB', 'MB', 'GB', 'TB']

export const IsNullOrUndefinedString = (value: string): boolean => {
  return (
    value === null ||
    value.toLowerCase() === 'null' ||
    value === undefined ||
    value.toLowerCase() === 'undefined' ||
    value.trim() === ''
  )
}

export const dateToString = (value: Date | string | number, dateFormat?: string): string => {
  try {
    return format(value instanceof Date ? value : new Date(value), dateFormat ?? 'dd/MM/yyyy')
  } catch (error) {
    return 'invalid date'
  }
}

export const titleToUrl = (value: string): string => {
  return value.toLowerCase().replace(/ /g, '-')
}

export const sizeOf = function (bytes: number): string {
  if (bytes === 0) {
    return '0.00 B'
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${' KMGTP'.charAt(e)}B`
}

export const findFirstDiff = (str1: string, str2: string): number =>
  [...str1].findIndex((el, index) => el !== str2[index])

export type ISortable = string | number | Date // | null
export type ISortDirection = 'desc' | 'asc' | 'none' | undefined
interface ISortKey<T extends Record<string | number | symbol, unknown>, K extends keyof T, V extends ISortable> {
  sortKey: K
  direction?: ISortDirection
  convert?: (val: unknown) => V
}

export const compare = <T>(a: T, b: T, dir?: ISortDirection, convert?: (val: unknown) => ISortable): number => {
  if (dir === 'none') return 0
  const sortVal = dir === 'desc' ? -1 : 1
  if (convert) {
    if (convert(a) > convert(b)) return sortVal
    if (convert(a) < convert(b)) return -sortVal
  } else {
    if (a > b) return sortVal
    if (a < b) return -sortVal
  }
  return 0
}
// eslint-disable-next-line
export const sorting = <T extends Record<string | number | symbol, any>, K extends keyof T, V extends ISortable>(
  a: T | null,
  b: T | null,
  keys: ISortKey<T, K, V>[],
): number => {
  return keys.reduce(
    (p, k) => (!p ? compare((a && a[k.sortKey]) || '', (b && b[k.sortKey]) || '', k.direction, k.convert) : p),
    0,
  )
}

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(true), time)
  })

export const logAppAccess = (appName: string) => {
  const page = location.hash.split('/')[1]
  const appInsights = appInsightsInstance()
  if (!appInsights) return
  switch (page) {
    case AppRoute.APPS:
      appInsights.trackEvent({ name: `AP Access ${appName}` })
      break
    case '':
      appInsights.trackEvent({ name: `HP Access ${appName}` })
      break
    default:
      break
  }
}

export const getServerUrlForSocket = () => {
  const backEndUrl = window.location.host
  const hostname = window.location.hostname

  const update = LOCAL_CORS.includes(hostname)

  return update ? `${hostname}:3000` : backEndUrl
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortArrBy = (order: string, arr: { [key: string]: any }[], sortKey: string) => {
  return [...arr].sort((a, b) => {
    const prev = a[sortKey]
    const next = b[sortKey]
    return order === 'asc'
      ? prev.toString().localeCompare(next.toString())
      : next.toString().localeCompare(prev.toString())
  })
}

export const getTextFromPdf = async (file: File | Blob | MediaSource) => {
  const content = await pdfToText(file)
  return content
}

const parser = new DOMParser()
export const getTextFromHTML = (html: string) => {
  return parser.parseFromString(html, 'text/html')?.body?.textContent ?? ''
}

export const isScrollonElementAtBottom = (props: IScrollChatData) => {
  const ratio = 1 - props.offsetHeight / props.scrollHeight
  const bottom = props.scrollTop / props.scrollHeight >= ratio
  return bottom
}

/**
 * Open url links in a New Tab or same page.
 * @param  {[string]} link URL that needs to be opened.
 * @param  {[boolean]} newTab Open in new tab if true otherwise open in same window.
 * @param  {[boolean]} render Render the url on the window (works only with sharepoint urls) if true otherwise download or follow default browser behaviour.
 */
export const openLink = (link: string, newTab: boolean, render: boolean) => {
  const clickLink = document.createElement('a')
  clickLink.href = `${link}${render ? '?web=1' : ''}`
  if (newTab) clickLink.target = '_blank'
  clickLink.click()
  clickLink.remove()
}

/**
 * Gets local storage item from browser by the key provided.
 * @param  {[string]} key Key of the storage item.
 */
export const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key)
}

/**
 * Sets local storage item in the browser by the key and value provided.
 * @param  {[string]} key Key of the storage item.
 * @param  {[string]} value Value of the storage item.
 */
export const setLocalStorageItem = (key: string, value: string) => {
  return window.localStorage.setItem(key, value)
}
