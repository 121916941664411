import { Icon } from '@aurecon-creative-technologies/styleguide'
import { TFunction } from 'i18next'

const selectedMultipleItems = (t: TFunction, iconClass: string) => (
  <>
    <Icon type='filter_alt' size='16px' cssClass='filterIcon' className={iconClass} />
    {t('filter')}
  </>
)

export default selectedMultipleItems
