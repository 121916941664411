import { FC } from 'react'
import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'

import Style from '../styles/StaticPage.module.sass'

export const ContactUs: FC = () => {
  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Contact Us' })
  return (
    <Page menu pageTitle='Contact Us' contentWrapper>
      <div className={Style.staticContent}>
        <p>
          Officia veniam consequat eiusmod ut fugiat mollit reprehenderit anim occaecat reprehenderit sint in deserunt.
          Labore cupidatat voluptate aliquip aute aliquip consectetur. Commodo ullamco ut non voluptate est nostrud
          voluptate culpa esse. Sunt do dolore dolor commodo consequat ad dolore. Eu duis ut velit laboris ea labore est
          ex sint.Ad dolore sint commodo duis occaecat deserunt. Tempor irure cupidatat sint fugiat voluptate eiusmod
          minim ipsum cupidatat cupidatat sit adipisicing magna consequat.
        </p>
        <p>
          Excepteur labore laboris non ex ea velit consequat proident ea ex enim. Fugiat nisi culpa elit do et ut amet
          cillum sint laborum deserunt ut. Et magna mollit esse dolor non et dolore officia aliquip eiusmod aute cillum.
          Dolor nostrud non labore do nostrud in nulla enim occaecat sit. Anim nostrud aliquip ex occaecat nostrud esse
          elit laboris ea eu adipisicing velit.
        </p>
        <p>
          Officia aliqua duis ut ut aute non veniam cillum ullamco. Pariatur dolor in proident voluptate cupidatat qui
          amet proident ex qui sit ea. Et commodo non duis cupidatat reprehenderit exercitation quis duis sint.
        </p>
        <p>
          Occaecat nostrud velit non tempor eiusmod nisi sit voluptate velit. In laboris esse ex id exercitation. Culpa
          anim incididunt eiusmod pariatur duis.
        </p>
      </div>
    </Page>
  )
}
