import { FC } from 'react'
import { IQuestion } from '../models/IQuestionModels'
import Style from '../styles/RelatedQuestions.module.sass'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatType, NomicSession, QuestionFile, QuestionInput } from '../stores/AppStore'
import { ChatTypeToPath } from '../enums/ChatTypeEnum'
import { appInsightsInstance } from '../api/AppInsights'
import classNames from 'classnames'

interface IRelatedQuestionProps {
  question: IQuestion
}

const RelatedQuestions: FC<IRelatedQuestionProps> = (props) => {
  const { question } = props
  const chatType = useRecoilValue(ChatType)
  const nomicSession = useRecoilValue(NomicSession)
  const setQuestionInput = useSetRecoilState(QuestionInput)
  const questionFiles = useRecoilValue(QuestionFile)

  const handleRelatedQuestion = (value: string) => {
    setQuestionInput(value)

    const appInsights = appInsightsInstance()
    if (chatType === null) return
    const eventName = `related-${nomicSession?.name ?? ChatTypeToPath[chatType]}-selected`

    if (appInsights) appInsights.trackEvent({ name: eventName })
  }

  const questionClasses = classNames({
    [Style.question]: true,
    [Style.hasFiles]: !!questionFiles?.length,
  })

  return (
    <div className={questionClasses}>
      <span className={Style.title}>Related Questions</span>
      {question.relatedQuestions?.map((item) => {
        return (
          <div key={item} className={Style.questionItem} role='none' onClick={() => handleRelatedQuestion(item)}>
            <Icon className={Style.icon} type='add' outlined size='14px' /> <span>{item}</span>
          </div>
        )
      })}
    </div>
  )
}

export default RelatedQuestions
