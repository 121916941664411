export const AppColour = {
  RECALL_CHAT: '#85C446',
  GPT_CHAT: '#F6D510',
  CODE_CHAT: '#B2E3F4',
  WINWISE: '#00796B',
  WINWISE_APP_PAGE: '#45C5B6',
  BAMBOO: '#C7B3E9',
  BAMBOO_APP_PAGE: '#E3D2FF',
  CUSTOM_RECALL_APP: '#1692BF',
  BHP_STANDARDS: '#F36A22',
  RIO_TINTO_STANDARDS: '#E60D2E',
  AUS_NET_STANDARDS: '#4B71C2',
  PM_APP: '#E5B776',
  CLIENTSENSE: '#E61747',
}
