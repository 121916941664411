import { FC } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/LoadingScreen.module.sass'
import classNames from 'classnames'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

interface ILoadingScreenProps {
  text?: string
  size?: 'small' | 'extra small' | 'medium' | 'large' | 'extra large'
  chatType?: number
  hideLabel?: boolean
  cssClass?: string
}

const LoadingScreen: FC<ILoadingScreenProps> = (props) => {
  const { chatType, hideLabel } = props

  const text = hideLabel ? undefined : props.text ?? 'Loading'

  const chatTypeClasses = () => {
    return classNames({
      [Style.winwise]: chatType === ChatTypeEnum.WINWISE,
    })
  }

  return (
    <div className={classNames(Style.loader, props.cssClass)}>
      <Loader label={text} size={props.size} cssClass={chatTypeClasses()} />
    </div>
  )
}

export default LoadingScreen
