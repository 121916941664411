import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon } from '@aurecon-creative-technologies/styleguide'

import Page from '../components/Page'
import config from '../config/config'
import { appInsightsInstance } from '../api/AppInsights'

import Style from '../styles/Profile.module.sass'

const Profile: FC = () => {
  const { user, logout } = useAuth0()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Profile' })

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ returnTo })
  }

  return (
    <Page menu>
      <div className={Style.layoutContainer}>
        <p style={{ marginTop: '32px' }}>
          <Icon type='person' size='64px' />
        </p>
        <p>{user?.email} (User)</p>
        <Button type='primary' label='Logout' width='300px' onClick={handleLogout} />
      </div>
    </Page>
  )
}

export default Profile
