import { FC } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Icon, Tooltip } from '@aurecon-creative-technologies/styleguide'

import { IQuestion } from '../models/IQuestionModels'
import { ChatSession, ChatType, CurrentQuestion, ShowEditHistoryModal } from '../stores/AppStore'
import ChatQuestionAttachments from './ChatQuestionAttachments'
import { formatText } from '../helpers/formatText'

import Style from '../styles/ChatQuestion.module.sass'
import classNames from 'classnames'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

interface IChatQuestionProps {
  question: IQuestion
  cssClass?: string
}

const ChatQuestion: FC<IChatQuestionProps> = (props) => {
  const setShowEditHistoryModal = useSetRecoilState(ShowEditHistoryModal)
  const setCurrentQuestion = useSetRecoilState(CurrentQuestion)
  const chatSession = useRecoilValue(ChatSession)

  const chatType = useRecoilValue(ChatType)
  const { question, cssClass } = props

  const handleAnswerHistory = () => {
    setCurrentQuestion(question)
    setShowEditHistoryModal(true)
  }

  const questionStyle = classNames({
    [Style.winwise]: chatSession?.type === ChatTypeEnum.WINWISE,
  })

  return (
    <div className={classNames(Style.question, cssClass)}>
      <div className={Style.questionText}>
        <div className={classNames(Style.avatar, questionStyle)}>
          <Icon type='account_circle' outlined />
        </div>
        <pre className={classNames(Style.text, questionStyle)}>
          {formatText(question.question)}
          {question.edited && (
            <Tooltip show='Show edit history' cssClass={Style.editedIcon}>
              <Icon type='edit_note' outlined onClick={handleAnswerHistory} />
            </Tooltip>
          )}
        </pre>
      </div>
      {chatType !== ChatTypeEnum.WINWISE && <ChatQuestionAttachments question={question} />}
    </div>
  )
}

export default ChatQuestion
