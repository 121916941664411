import { useMsal } from '@azure/msal-react'
import { useCallback } from 'react'

export const useMsalInfo = () => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  const getToken = useCallback(async () => {
    if (!account) return ''
    const tokenData = await instance.acquireTokenSilent({ account, scopes: [] })
    const accessToken = tokenData.accessToken
    return accessToken
  }, [account, instance])

  return { getToken }
}
