import { FC } from 'react'
import { ReactComponent as DocIcon } from '../../../src/assets/docx_icon.svg'
import { ReactComponent as PptIcon } from '../../../src/assets/ppt_icon.svg'
import { ReactComponent as TxtIcon } from '../../../src/assets/txt_icon.svg'
import { ReactComponent as XlsxIcon } from '../../../src/assets/xlsx_icon.svg'
import { ReactComponent as PdfIcon } from '../../../src/assets/pdf_icon.svg'
import { FileMimeType } from '../../enums/FileTypeEnum'

interface IRecallFileTypeIconProps {
  fileMimeType: string
}

const RecallFileTypeIcon: FC<IRecallFileTypeIconProps> = (props) => {
  const { fileMimeType } = props

  const fileTypeToIconMap = {
    [FileMimeType.DOC]: <DocIcon />,
    [FileMimeType.DOCX]: <DocIcon />,
    [FileMimeType.PPT]: <PptIcon />,
    [FileMimeType.PPTX]: <PptIcon />,
    [FileMimeType.XLS]: <XlsxIcon />,
    [FileMimeType.XLSX]: <XlsxIcon />,
    [FileMimeType.PDF]: <PdfIcon />,
  }

  return fileTypeToIconMap[fileMimeType] || <TxtIcon />
}

export default RecallFileTypeIcon
