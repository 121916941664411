export const WinwiseStatusEnum = {
  IN_PROGRESS: 1,
  ARCHIVED: 2,
  SHARED: 3,
}

export const WinwiseStatusLabel = {
  [WinwiseStatusEnum.IN_PROGRESS]: 'In Progress',
  [WinwiseStatusEnum.ARCHIVED]: 'Archived',
  [WinwiseStatusEnum.SHARED]: 'Shared with me',
}

export const WinwiseStatusColor = {
  [WinwiseStatusEnum.IN_PROGRESS]: 5,
  [WinwiseStatusEnum.SHARED]: 6,
  [WinwiseStatusEnum.ARCHIVED]: 7,
}
