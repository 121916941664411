import { deleteAsync, getAsync, postAsync } from '../helpers/apiRequest'
import { IWinwiseShareResponseModel } from '../models/api/ICreateChatModels'
import {
  ICreateWinwiseShareRequestModel,
  IGetWinwiseResponseModal,
  IRemoveWinwiseShareRequestModel,
} from '../models/api/ICreateWinwiseModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

const url = '/v1/winwiseShare'

export const getAllWinwiseShares = TokenExpiryWrapper(
  (request: IGetWinwiseResponseModal): Promise<IResponse<IWinwiseShareResponseModel[]>> =>
    getAsync(`${url}/${request.id}`, request),
  [],
  null,
)

export const createWinwiseShare = TokenExpiryWrapper(
  (request: ICreateWinwiseShareRequestModel): Promise<IResponse<IWinwiseShareResponseModel[]>> =>
    postAsync(url, request),
  [],
  null,
)

export const deleteWinwiseShare = TokenExpiryWrapper(
  (request: IRemoveWinwiseShareRequestModel): Promise<IResponse<boolean>> => deleteAsync(url, request),
  [],
  null,
)
