import { FC, useState } from 'react'
import { Grid } from '@aurecon-creative-technologies/styleguide'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/ManageAccessModal.module.sass'
import { useLanguages } from '../../hooks/useLanguages'
import { IWinwiseOpportunity } from '../../models/IWinwiseOpportunity'
import { DefaultWinwiseProperty } from '../../pages/WinwiseHome'
import {
  WinwiseOpportunityFields,
  winwiseOpportunityValidatorSchema,
} from '../../validators/winwiseOpportunityValidator'
import LimitedCharactersLeft from '../common/LimitedCharacters'

interface IOpportunityModalProps {
  open: boolean
  winwiseOpportunity: IWinwiseOpportunity
  onSave: (data: IWinwiseOpportunity) => void
  onClose: () => void
  loading?: boolean
}

const OpportunityModal: FC<IOpportunityModalProps> = (props) => {
  const { open, winwiseOpportunity, onSave, onClose, loading } = props
  const [winwiseProperty, setWinwiseProperty] = useState<IWinwiseOpportunity>(winwiseOpportunity)
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)
  const { t } = useLanguages()

  const handleModalClose = () => {
    setWinwiseProperty(DefaultWinwiseProperty)
    setErrors({})
    onClose()
  }

  const validateWinwiseProperty = (currenOpp: IWinwiseOpportunity) => {
    const validationResult = winwiseOpportunityValidatorSchema().validate(
      { name: currenOpp.name, clientName: currenOpp.clientName },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string) => {
    const updatedProperty = { ...winwiseProperty, [field]: value }
    setWinwiseProperty(updatedProperty)
    const errors = validateWinwiseProperty(updatedProperty)
    setErrors(errors)
  }

  const handleSaveProperty = () => {
    const errors = validateWinwiseProperty(winwiseProperty)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    onSave({
      ...winwiseProperty,
      id: winwiseProperty.id,
    })

    handleModalClose()
  }

  const labelYes = () => {
    return winwiseProperty.id ? t('update') : t('create')
  }

  const onYesClicked = () => {
    handleSaveProperty()
  }

  const createButtonDisabled = !!Object.keys(errors).length || !winwiseProperty.clientName || !winwiseProperty.name
  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.WINWISE}
      isShowing={open}
      onSave={onYesClicked}
      onClose={handleModalClose}
      disabled={createButtonDisabled}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={labelYes()}
      loadingYes={loading}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>{winwiseProperty.id ? t('update') : t('create')}</h2>
        <Grid row gap={12}>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('enter_client_name')}
              value={winwiseProperty.clientName}
              onChange={(val) => handleValueChange(WinwiseOpportunityFields.clientName.fieldName, val)}
              cssClass={Style.description}
              label={t('client_name')}
              error={errors.clientName?.[0]}
              chatType={ChatTypeEnum.WINWISE}
            />
            {!errors.clientName?.[0] && (
              <LimitedCharactersLeft
                maxLength={WinwiseOpportunityFields.clientName.maxLength}
                value={winwiseProperty.clientName}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('enter_opportunity_name')}
              value={winwiseProperty.name}
              onChange={(val) => handleValueChange(WinwiseOpportunityFields.name.fieldName, val)}
              cssClass={Style.description}
              label={t('opportunity_name')}
              chatType={ChatTypeEnum.WINWISE}
              error={errors.name?.[0]}
            />
            {!errors.name?.[0] && (
              <LimitedCharactersLeft maxLength={WinwiseOpportunityFields.name.maxLength} value={winwiseProperty.name} />
            )}
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default OpportunityModal
