import { FC, useEffect } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'

import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatSession, QuestionFile } from '../stores/AppStore'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'
import { useInitializeChatSession } from '../hooks/useInitializeChatSession'

const ChatGptHome: FC = () => {
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const { t, i18n } = useLanguages()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Secure ChatGPT Home' })

  useInitializeChatSession(ChatTypeEnum.GPT)
  useEffect(() => {
    if (!chatSession) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setQuestionFile(null)
  }, [setQuestionFile])

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      <div className={Style.pageContents}>
        <h1 className={Style.chatTitle}>Secure ChatGPT</h1>
        <ChatSwitcher activeChat={ChatTypeEnum.GPT} />
        <p> {t('securegpt_desc')}</p>
        <p>{t('securegpt_desc2')}</p>
        <p>{t('securegpt_desc3')}</p>
        <div className={Style.examples}>
          <h3>{t('navigation_tip')}</h3>
          <Grid row>
            <div>
              <p>
                <Icon type='keyboard' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>
                <Trans i18nKey='navigation' />
              </p>
            </div>
          </Grid>

          <Grid row>
            <div>
              <p>
                <Icon type='post_add' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>{t('securegpt_desc4')}</p>
            </div>
          </Grid>
        </div>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatGptHome
