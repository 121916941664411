import { createAuth0Client } from '@auth0/auth0-spa-js'
import jwt_decode from 'jwt-decode'
import config, { USER_OID, AUTH0_OFFLINE_ACCESS_SCOPE, AUTH_READ_CURRENT_USER } from '../config/config'
import { IAppConfig } from './AppService'

export const EmailScope = 'email'

interface IAccessToken {
  ['https://aurecon.com/email']: string
  ['https://aurecon.com/name']: string
  ['https://aurecongroup.com/roles']: string[]
  ['https://aurecongroup.com/user_metadata']: string
  ['https://aurecon.com/oid']: string
  ['iss']: string
  ['sub']: string
  ['aud']: string[]
  ['iat']: string
  ['exp']: string
  ['scope']: string
  ['azp']: string
}
export type IAPIRequest<T, U> = (props: T) => Promise<U>

// eslint-disable-next-line
let tokenMgr: any = null

// eslint-disable-next-line
export function TokenManager() {
  if (tokenMgr) return tokenMgr

  const EMPTY_STRING_ARRAY: string[] = []
  const NO_TOKEN = ''
  const CONSENT_REQUIRED = 'consent_required'
  const MISSING_REFRESH_TOKEN = 'missing_refresh_token'
  const LOGIN_REQUIRED = 'login_required'
  const INVALID_GRANT = 'invalid_grant'

  const token = {
    jwt: NO_TOKEN,
    scopes: EMPTY_STRING_ARRAY,
    expired: false,
  }

  tokenMgr = new Promise((resolve) => {
    createAuth0Client({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENT_ID,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        scope: config.AUTH0_SCOPE,
        audience: config.AUTH0_AUDIENCE,
      },
    }).then((auth0) => {
      async function tokenRequest(scopes: string[]) {
        const scope = [AUTH0_OFFLINE_ACCESS_SCOPE, AUTH_READ_CURRENT_USER, ...scopes].join(' ')

        try {
          return await getTokenSilently(scope)

          // eslint-disable-next-line
        } catch (error: any) {
          if (error.error === CONSENT_REQUIRED) {
            return CONSENT_REQUIRED
          }

          if (error.error === MISSING_REFRESH_TOKEN) {
            try {
              const popupToken = await getTokenSilently(scope)
              return popupToken ?? LOGIN_REQUIRED
            } catch (popupError) {
              return NO_TOKEN
            }
          }

          if (error.error === LOGIN_REQUIRED) {
            console.log(`Error ${error.error}`)
            return LOGIN_REQUIRED
          }

          if (error.error === INVALID_GRANT) {
            console.log(`Error ${error.error}`)
            return INVALID_GRANT
          }

          return `error:${error.error}`
        }
      }

      async function requestToken(scopes: string[]): Promise<string> {
        const jwtToken = token.jwt

        if (jwtToken && !token.expired) {
          return jwtToken
        }

        const newToken = await tokenRequest(scopes)
        if (newToken === LOGIN_REQUIRED || newToken === CONSENT_REQUIRED || newToken === INVALID_GRANT) {
          try {
            const popupToken = await initiateConsentPopup()
            if (popupToken) {
              token.jwt = popupToken
              token.scopes = scopes
              return token.jwt
            }
            return newToken
          } catch (popupError) {
            return newToken
          }
        }

        if (typeof newToken === 'string' && newToken.startsWith('error:')) {
          return NO_TOKEN
        }

        token.jwt = newToken
        token.scopes = scopes
        return token.jwt
      }

      function initiateConsentPopup(): Promise<string | undefined> {
        return auth0.getTokenWithPopup({
          authorizationParams: {
            audience: config.AUTH0_AUDIENCE,
            scope: config.AUTH0_SCOPE,
          },
        })
      }

      function getTokenSilently(scope: string): Promise<string> {
        return auth0.getTokenSilently({
          authorizationParams: {
            audience: config.AUTH0_AUDIENCE,
            scope: scope,
          },
        })
      }

      function handleTokenExpiry() {
        token.expired = true
      }

      function checkTokenExpiration(tokenString: string) {
        try {
          if (!tokenString) return true
          //Here we are checking for expiry token which is one possibility when 401 occurs
          const decoded = jwt_decode<IAccessToken>(tokenString)
          const now = Date.now() / 1000
          const expiryString = decoded ? decoded['exp'] : null
          const expiryEpoch = expiryString ? parseInt(expiryString) : now

          return expiryEpoch < now
        } catch (error) {
          console.error("** Can't decode token...")
          console.error(error)
          return false
        }
      }

      function getUserOID() {
        try {
          const decoded = jwt_decode<IAccessToken>(token.jwt)
          return decoded[USER_OID]
        } catch (error) {
          return 'error'
        }
      }

      tokenMgr = {
        requestToken,
        initiateConsentPopup,
        handleTokenExpiry,
        checkTokenExpiration,
        getUserOID,
      }

      resolve(tokenMgr)
    })
  })
  return tokenMgr
}

export async function getNomicToken(scopes: string[], nomic_config: IAppConfig) {
  console.log('** Creating client...')
  const nomicAuth0Client = await createAuth0Client({
    domain: nomic_config.NOMIC_AUTH0_DOMAIN,
    clientId: nomic_config.NOMIC_AUTH0_CLIENT_ID,
    issuer: nomic_config.NOMIC_AUTH0_ISSUER,
    authorizationParams: {
      audience: nomic_config.NOMIC_AUTH0_AUDIENCE,
    },
  })
  const scope = [...scopes].join(' ')

  try {
    console.log('** Getting token...')
    const token = await nomicAuth0Client.getTokenSilently({
      authorizationParams: {
        scope,
      },
    })

    return token
  } catch (err) {
    console.log(err)
    console.log('** Asking user to relogin into Nomic...')

    await nomicAuth0Client.loginWithPopup({
      authorizationParams: {
        scope,
      },
    })
    const token = (await getNomicToken([], nomic_config)) as string | null
    return token
  }
}

export function TokenExpiryWrapper<T, U>(
  apiCall: IAPIRequest<T, U>,
  scopes: string[],
  errorReturnValue: U,
): IAPIRequest<T, U> {
  return async (props: T) => {
    const tokenManager = await TokenManager()
    let token = await tokenManager.requestToken(scopes)
    if (!token) {
      return errorReturnValue
    }
    let expired = tokenManager.checkTokenExpiration(token)

    if (expired) {
      try {
        tokenMgr.handleTokenExpiry()
        token = await tokenMgr.requestToken(scopes)
        if (!token) {
          return errorReturnValue
        }
      } catch (err2) {
        return errorReturnValue
      }
    }

    try {
      return await apiCall({ token, ...props })

      // eslint-disable-next-line
    } catch (error: any) {
      if (error.status === 401) {
        expired = tokenManager.checkTokenExpiration(token)
        if (expired) {
          try {
            tokenMgr.handleTokenExpiry()
            token = await tokenMgr.requestToken(scopes)
            return await apiCall({ token, ...props })
          } catch (err2) {
            return errorReturnValue
          }
        }
        console.log('Not authorized to access resource')
        return errorReturnValue
      }
      if (error.status === 500) {
        return errorReturnValue
      }
      console.log('handle error response which probably should be considered')
      console.log(error)
      return errorReturnValue
    }
  }
}
