import { FC } from 'react'
import { Grid } from '@aurecon-creative-technologies/styleguide'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/ManageAccessModal.module.sass'
import { useRecoilValue } from 'recoil'
import { FullScreen } from '../../stores/AppStore'

interface INotificationModalProps {
  open: boolean
  onClose: () => void
  title: string
  description: string | JSX.Element
}

const NotificationModal: FC<INotificationModalProps> = (props) => {
  const { open, title, description, onClose } = props

  const fullScreen = useRecoilValue(FullScreen)

  const handleModalClose = () => {
    onClose()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onClose={handleModalClose}
      disabled={true}
      size={fullScreen ? 'medium' : 'small'}
      hideYes={true}
      hideCloseIcon={true}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>{title}</h2>
        <p className={Style.notificationDescription}>{description}</p>
        <Grid row gap={12}></Grid>
      </div>
    </RecallModal>
  )
}

export default NotificationModal
