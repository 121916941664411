export const AppRoute = {
  RECALL_CHAT: 'chat-recall',
  GPT_CHAT: 'chat-gpt',
  CODE_CHAT: 'chat-code',
  VISION_CHAT: 'chat-vision',
  BAMBOO_CHAT: 'chat-bamboo',
  HISTORY: 'history',
  DASHBOARD: 'dashboard',
  ADMIN: 'home-page-admin',
  APPS: 'apps',
  USER_GUIDE: 'user-guide',
  CONTACT_US: 'contact',
  PDF_VIEWER: 'pdf-viewer',
  CUSTOM_RECALL_APP: 'home-custom-recall-app',
  WINWISE: 'home-winwise',
  WINWISE_THREAD: 'winwise-thread',
  CUSTOM_RECALL_APP_CHAT: 'chat-custom-recall-app',
  BHP_STANDARDS: 'chat-bhp-standards',
  RIO_TINTO_STANDARDS: 'chat-rio-tinto-standards',
  AUSNET_STANDARDS: 'chat-ausnet-standards',
  PM_CHAT: 'chat-pm',
}

export const subAppRoute = {
  CRA_CHAT: 'cra-chat',
}
