import {
  Button,
  Dropdown,
  Icon,
  IOption,
  IOverflowMenuItemProps,
  Loader,
  Modal,
  OverflowMenu,
  Pill,
  Table,
  TableCell,
  TableRow,
  ToastTypes,
  Tooltip,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { appInsightsInstance } from '../api/AppInsights'
import { FC, useEffect, useMemo, useState, useCallback } from 'react'
import Page from '../components/Page'
import Style from '../styles/WinwiseHome.module.sass'
import { IWinwiseResponseModel, IWinwiseShareResponseModel } from '../models/api/ICreateChatModels'
import LoadingScreen from '../components/LoadingScreen'
import { format, parseISO } from 'date-fns'
import { sortArrBy } from '../helpers/utils'
import { twelveHourFormat } from '../config/config'
import { useLanguages } from '../hooks/useLanguages'
import { IWinwiseOpportunity } from '../models/IWinwiseOpportunity'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { FullScreen, WinwiseOpportunityLoader } from '../stores/AppStore'
import OpportunityModal from '../components/modals/OpportunityModal'
import { archiveOpportunity, createWinwiseOpportunity } from '../api/winwiseService'
import { WinwiseStatusColor, WinwiseStatusEnum, WinwiseStatusLabel } from '../enums/WinwiseStatusEnum'
import { ResponseData } from '../models/api/IResponse'
import { createWinwiseShare, deleteWinwiseShare, getAllWinwiseShares } from '../api/winwiseShareService'
import ManageAccessModal from '../components/modals/ManageAccessModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { IGenericModel } from '../models/api/IGenericModel'
import selectedMultipleItems from '../components/SelectedMultipleItems'
import { AppRoute } from '../enums/AppRouteConstants'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useNomicToken } from '../hooks/useNomicToken'

export const DefaultWinwiseProperty: IWinwiseOpportunity = {
  id: '',
  clientName: '',
  name: '',
}

const ContextMenuItemIds = {
  OPEN: '1',
  MANAGE_ACCESS: '2',
  ARCHIVE: '3',
  REMOVE_ACCESS: '4',
  UNARCHIVE: '5',
}

const defaultSortOrder: IWWTableSortOrder = {
  name: 'none',
  status: 'none',
  updatedAt: 'none',
}

type sortType = 'none' | 'desc' | 'asc' | undefined

interface IWWTableSortOrder {
  name: sortType
  status: sortType
  updatedAt: sortType
}

const columnWidth = {
  name: '56%',
  status: '19%',
  dateAdded: '20%',
  action: '5%',
}

type pillType = 1 | 2 | 3 | 4

const DEFAULT_FILTERS = { toggleAll: 'toggleAll', myApp: 'myApp', sharedWithMe: 'sharedWithMe' }
const SELECTED_FILTERS = [DEFAULT_FILTERS.myApp, DEFAULT_FILTERS.sharedWithMe]

const getCursorStyle = (status: (typeof WinwiseStatusEnum)[keyof typeof WinwiseStatusEnum]) =>
  status === WinwiseStatusEnum.ARCHIVED || status === WinwiseStatusEnum.SHARED ? 'pointer' : 'default'

const WinwiseHome: FC = () => {
  const { user } = useAuth0()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'WinWise by clientSense Home' })
  const [editOpportunity, setEditOpportunity] = useState<IWinwiseOpportunity | null>(null)
  const [winwiseList, setWinwiseList] = useState<IWinwiseResponseModel[]>()
  const [sortOrder, setSortOrder] = useState<IWWTableSortOrder>(defaultSortOrder)
  const [winwiseShareList, setWinwiseShareList] = useState<IWinwiseShareResponseModel[] | null>(null)
  const [openManageAccessOpp, setOpenManageAccessOpp] = useState<IWinwiseResponseModel | null>(null)

  const fullScreen = useRecoilValue(FullScreen)
  const winwiseOpportunityLoader = useRecoilValueLoadable(WinwiseOpportunityLoader)
  const refreshWinwiseOpportunities = useRecoilRefresher_UNSTABLE(WinwiseOpportunityLoader)

  const [loadingRes, setLoadingRes] = useState<boolean>(false)
  const [loadingShareList, setLoadingShareList] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [filters, setFilters] = useState<string[]>(SELECTED_FILTERS)
  const { addToast } = useToast()
  const { t } = useLanguages()
  const navigate = useNavigate()
  const { nomicToken } = useNomicToken(ChatTypeEnum.WINWISE)

  const inProgressContextMenu = (shareCount: number): IOverflowMenuItemProps[] => [
    { id: ContextMenuItemIds.OPEN, label: t('open') },
    {
      id: ContextMenuItemIds.MANAGE_ACCESS,
      label: shareCount ? `${t('manage_access')} (${shareCount})` : t('manage_access'),
    },
    {
      id: ContextMenuItemIds.ARCHIVE,
      label: t('archive'),
    },
  ]

  const isOwner = useCallback(
    (owner: string) => owner.toLocaleLowerCase() === user?.email?.toLowerCase(),
    [user?.email],
  )
  const sharedContextMenu: IOverflowMenuItemProps[] = [{ id: ContextMenuItemIds.OPEN, label: 'Open' }]

  const archivedContextMenu: IOverflowMenuItemProps[] = [
    { id: ContextMenuItemIds.OPEN, label: t('open') },
    { id: ContextMenuItemIds.UNARCHIVE, label: t('unarchive') },
  ]

  const renderContextMenuOptions = (status: number, shareCount: number, owner: string) => {
    const menus = {
      [WinwiseStatusEnum.IN_PROGRESS]: () => inProgressContextMenu(shareCount),

      [WinwiseStatusEnum.ARCHIVED]: () =>
        isOwner(owner) ? archivedContextMenu : archivedContextMenu.filter((c) => c.id !== ContextMenuItemIds.UNARCHIVE),
      [WinwiseStatusEnum.SHARED]: () => sharedContextMenu,
    }
    return menus[status]()
  }

  useEffect(() => {
    if (winwiseOpportunityLoader.state !== 'hasValue' || !winwiseOpportunityLoader.contents) return
    setWinwiseList(winwiseOpportunityLoader.contents)
  }, [winwiseOpportunityLoader.contents, winwiseOpportunityLoader.state])

  const filterItems = [
    { id: DEFAULT_FILTERS.myApp, label: t('my_app') },
    { id: DEFAULT_FILTERS.sharedWithMe, label: t('share_app') },
  ]

  const onFilterChange = (items: (string | number)[]) => {
    const selectedItems = items.map((item) => String(item))

    const newFilters = selectedItems.includes(DEFAULT_FILTERS.toggleAll)
      ? Object.values(DEFAULT_FILTERS)
      : selectedItems

    setFilters(newFilters)
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      updatedAt: sort === 'none' && field !== 'updatedAt' ? 'desc' : ('none' as sortType),
      [field]: sort,
    }
    setSortOrder(newOrder)
  }

  const filteredAndSortedList = useMemo(() => {
    let filteredApps = winwiseList || []

    if (filters.length !== SELECTED_FILTERS.length) {
      filteredApps = filteredApps.filter((app) => {
        if (filters.includes(DEFAULT_FILTERS.myApp)) return isOwner(app.createdBy)
        if (filters.includes(DEFAULT_FILTERS.sharedWithMe)) return !isOwner(app.createdBy)

        return false
      })
    }

    const foundSortKey = (Object.keys(sortOrder) as [keyof IWWTableSortOrder]).find(
      (key) => sortOrder[key] === 'asc' || sortOrder[key] === 'desc',
    )

    const sortKey = foundSortKey ?? 'updatedAt'
    const order = sortOrder[sortKey]

    return sortArrBy(order as string, filteredApps, sortKey) as IWinwiseResponseModel[]
  }, [winwiseList, filters, sortOrder, isOwner])

  const getStatusColor = (status: number) => {
    if (status === WinwiseStatusEnum.ARCHIVED) return WinwiseStatusColor[WinwiseStatusEnum.ARCHIVED]
    if (status === WinwiseStatusEnum.SHARED) return WinwiseStatusColor[WinwiseStatusEnum.SHARED]

    return WinwiseStatusColor[WinwiseStatusEnum.IN_PROGRESS]
  }

  const getOpportunitySharedAccess = async (opportunityId: string) => {
    setLoadingShareList(true)
    const data = await getAllWinwiseShares({ id: opportunityId })
    setLoadingShareList(false)

    return data
  }

  const getAndRefreshWinwiseShares = async (oppId: string) => {
    const response = ResponseData(await getOpportunitySharedAccess(oppId))
    setWinwiseShareList(response ?? [])
  }

  const onContextMenuItemSelect = async (item: IOverflowMenuItemProps, winwiseOpp: IWinwiseResponseModel) => {
    if (item.id === ContextMenuItemIds.MANAGE_ACCESS) {
      setOpenManageAccessOpp(winwiseOpp)
      await getAndRefreshWinwiseShares(winwiseOpp.id)
    }

    if (item.id === ContextMenuItemIds.OPEN) {
      navigateToWinwiseThread(winwiseOpp.id)
    }

    const archivedStatus = [ContextMenuItemIds.ARCHIVE, ContextMenuItemIds.UNARCHIVE]
    if (isOwner(winwiseOpp.createdBy) && archivedStatus.includes(item.id)) {
      const requestBody = {
        id: winwiseOpp.id,
        archived: !winwiseOpp.archived,
      }
      await archiveOpportunity(requestBody)
      refreshWinwiseOpportunities()
    }
  }

  const onManageAccessIconClick = async (winwiseOpp: IWinwiseResponseModel) => {
    setOpenManageAccessOpp(winwiseOpp)
    await getAndRefreshWinwiseShares(winwiseOpp.id)
  }

  const createOpportunity = async (data: IWinwiseOpportunity) => {
    setLoadingRes(true)
    setIsSubmitting(true)

    if (!nomicToken) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('cra_mess7'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setLoadingRes(false)
      return
    }

    const res = await createWinwiseOpportunity({ ...data, nomicToken })

    let message = t('winwise_mess')
    let title = t('popup_toast')
    let type = 'success' as ToastTypes

    if (!res?.data) {
      message = 'Please contact the admin to create the organization on Nomic first'
      title = t('popup_toast2')
      type = 'error'
    }
    setIsSubmitting(false)
    setLoadingRes(false)

    addToast({
      type,
      title,
      message,
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    refreshWinwiseOpportunities()
    if (res?.data?.id) navigateToWinwiseThread(res.data.id)
  }

  const shareOpportunity = async (users: IOption[], app: IGenericModel) => {
    const opp = app as IWinwiseResponseModel
    if (!opp.id || !users.length) return

    const usersData = users.map((u) => ({
      auth0UserId: u.id as string,
      email: u.value,
      name: u.display?.toString().split('(')[0].trim() ?? '',
    }))

    setLoadingRes(true)

    const res = await createWinwiseShare({ id: opp.id, usersData, nomicToken })

    const data = res?.data

    if (!data) {
      addToast({
        type: 'error',
        message: t('share_mess2', { userName: usersData.map((user) => user.name).join(', ') }),
        timeout: 5000,
        title: t('popup_toast2'),
        timeLabel: t('popup_toast_timelabel'),
      })
      setLoadingRes(false)
      return
    }

    const createdUsers: string[] = []

    users.forEach((user) => {
      const name = (user.display as string).split('(')[0].trim()
      createdUsers.push(name ?? '')
    })

    const createdMessageKey = createdUsers.length > 1 ? 'share_mess_plural' : 'share_mess'
    const createdMessage = createdUsers.length
      ? t(createdMessageKey, {
          userNames: createdUsers.join(', '),
          userName: createdUsers[0],
        })
      : ''

    addToast({
      type: 'success',
      message: createdMessage,
      title: t('popup_toast'),
      timeLabel: t('popup_toast_timelabel'),
      timeout: 5000,
    })

    setLoadingRes(false)
    await getAndRefreshWinwiseShares(opp.id)
    refreshWinwiseOpportunities()
  }

  const removeOpportunityAccess = async (oppId: string, userId: string, name: string) => {
    const removeRes = ResponseData(await deleteWinwiseShare({ id: oppId, userId, nomicToken }))

    if (!removeRes) {
      addToast({
        type: 'error',
        message: t('revoke_mess2', { userName: name }),
        title: t('popup_toast2'),
        timeLabel: t('popup_toast_timelabel'),
        timeout: 5000,
      })

      return
    }

    addToast({
      type: 'success',
      message: t('revoke_mess', { userName: name }),
      title: t('popup_toast'),
      timeLabel: t('popup_toast_timelabel'),
      timeout: 5000,
    })

    await getAndRefreshWinwiseShares(oppId)
    refreshWinwiseOpportunities()
  }

  const onManageAccessClose = () => {
    setOpenManageAccessOpp(null)
    setWinwiseShareList(null)
  }

  const navigateToWinwiseThread = (opportunityId: string) => {
    navigate(`/${AppRoute.WINWISE_THREAD}/${opportunityId}`)
  }

  const renderAppList = () => {
    return (
      <>
        <div className={Style.header}>
          <h2>WinWise by clientSense</h2>
          <p className={Style.headerSubText}>{t('winwise_desc')}</p>
          <p className={Style.headerSubText}>{t('winwise_desc2')}</p>
          <div>
            <Button
              label={t('create')}
              cssClass={Style.createTopButton}
              onClick={() => setEditOpportunity(DefaultWinwiseProperty)}
            />
          </div>
        </div>
        <hr className={Style.divider} />
        <div className={Style.filterContainer}>
          <Dropdown
            cssClass='craFilterDropdown'
            multiple
            toggleAllItem={t('toggle_all')}
            items={filterItems}
            onSelectMultipleItems={onFilterChange}
            selectedMultipleItems={filters}
            selectedMultipleItemsRender={() => selectedMultipleItems(t, Style.filterIcon)}
          />
        </div>
        <Table
          headers={[
            {
              label: t('opportunity_name'),
              sort: sortOrder.name,
              onSort: (sort) => onSort('name', sort),
              style: { width: columnWidth.name },
            },
            {
              label: t('status'),
              sort: sortOrder.status,
              onSort: (sort) => onSort('status', sort),
              style: { width: columnWidth.status },
            },
            {
              label: t('dated_added'),
              sort: sortOrder.updatedAt,
              onSort: (sort) => onSort('updatedAt', sort),
              style: { width: columnWidth.dateAdded },
            },
            { label: t('action'), style: { width: columnWidth.action } },
          ]}
          cssClass={Style.winwiseTable}
        >
          {filteredAndSortedList.map((o) => (
            <TableRow
              key={o.id}
              rowClass={Style.winwiseTableRow}
              style={{
                cursor: getCursorStyle(o.status),
              }}
            >
              <TableCell
                title={o.name}
                cellClass={Style.cell}
                style={{
                  width: columnWidth.action,
                  cursor: getCursorStyle(o.status),
                }}
                onClick={() => navigateToWinwiseThread(o.id)}
              >
                {o.clientName} - {o.name}
              </TableCell>
              <TableCell
                cellClass={Style.cell}
                style={{
                  width: columnWidth.status,
                  cursor: getCursorStyle(o.status),
                }}
                onClick={() => navigateToWinwiseThread(o.id)}
              >
                <Pill
                  size={fullScreen ? 'medium' : 'small'}
                  colour={getStatusColor(o.status) as pillType}
                  cssClass={`cra-pill-status-${o.status}`}
                >
                  {WinwiseStatusLabel[o.status]}
                </Pill>
                {!!o.shareCount && (
                  <Tooltip show={t('manage_access')}>
                    <div
                      className={Style.manageAccessIconWrap}
                      onClick={(e) => {
                        e.stopPropagation()
                        onManageAccessIconClick(o)
                      }}
                      role='none'
                    >
                      <Icon type='group' style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                cellClass={Style.date}
                style={{
                  width: columnWidth.dateAdded,
                  cursor: getCursorStyle(o.status),
                }}
                onClick={() => navigateToWinwiseThread(o.id)}
              >
                {format(parseISO(o.updatedAt), twelveHourFormat)}
              </TableCell>
              <TableCell cellClass={Style.action} style={{ width: columnWidth.action }}>
                <div role='none' className={Style.actionWrapper}>
                  <OverflowMenu
                    items={renderContextMenuOptions(o.status, o.shareCount, o.createdBy)}
                    size='extra small'
                    icon='more_vert'
                    default
                    cssClass='winwise-overflow-menu'
                    onSelectItem={(i) => onContextMenuItemSelect(i, o)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        {renderAppList()}
        {!!editOpportunity && (
          <OpportunityModal
            open={!!editOpportunity}
            winwiseOpportunity={editOpportunity}
            onSave={createOpportunity}
            onClose={() => setEditOpportunity(null)}
            loading={loadingRes}
          />
        )}
        {openManageAccessOpp && (
          <ManageAccessModal
            open={true}
            onSave={shareOpportunity}
            onRevoke={removeOpportunityAccess}
            pickedItem={openManageAccessOpp}
            shareList={winwiseShareList ?? []}
            loading={loadingRes}
            loadingTable={loadingShareList}
            onClose={onManageAccessClose}
            chatType={ChatTypeEnum.WINWISE}
            description={t('winwise_manage_access_desc', { opportunityName: openManageAccessOpp.name })}
          />
        )}
        <Modal isShowing={isSubmitting} onClose={() => 0} size='small' isCloseButton={false}>
          <div className={Style.loaderContainer}>
            <Loader label={t('loading')} />
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Page menu contentWrapper>
      {!winwiseList ? <LoadingScreen text='Loading opportunities' /> : renderBody()}
    </Page>
  )
}

export default WinwiseHome
