import { FC, useEffect, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { CapabilitiesLoader, FullScreen } from '../../stores/AppStore'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import Style from '../../styles/ManageAccessModal.module.sass'
import RecallDropdown from '../common/RecallDropdown'
import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'

interface ICreateNomicOrgModalProps {
  open: boolean
  onClose: () => void
  pickedCapabilities: string[]
  onNextClicked: (capabilities: string[]) => void
}

export const ALL_CAPABILITIES_ID = 'All Capabilities'

const CapabilityModal: FC<ICreateNomicOrgModalProps> = (props) => {
  const { open, onClose, pickedCapabilities, onNextClicked } = props
  const fullScreen = useRecoilValue(FullScreen)
  const capabilitiesLoader = useRecoilValueLoadable(CapabilitiesLoader)

  const [selectedCapabilities, setSelectedCapabilities] = useState<string[]>([])

  const [capabilities, setCapabilities] = useState<IDropdownItemProps[]>([])

  useEffect(() => {
    if (pickedCapabilities.includes(ALL_CAPABILITIES_ID)) {
      setSelectedCapabilities(capabilities.map((cap) => cap.id.toString()))
      return
    }
    setSelectedCapabilities(pickedCapabilities)
  }, [capabilities, pickedCapabilities])

  useEffect(() => {
    if (capabilitiesLoader.state !== 'hasValue' || !capabilitiesLoader.contents) return

    setCapabilities(capabilitiesLoader.contents.filter((item) => item.id !== ALL_CAPABILITIES_ID))
  }, [capabilitiesLoader.contents, capabilitiesLoader.state])

  const handleModalClose = () => {
    onClose()
  }

  const onNextClick = () => {
    onNextClicked(selectedCapabilities.length === capabilities.length ? [ALL_CAPABILITIES_ID] : selectedCapabilities)
    setSelectedCapabilities([])
    onClose()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.RECALL}
      isShowing={open}
      onSave={onNextClick}
      onClose={handleModalClose}
      disabled={false}
      size={fullScreen ? 'medium' : 'small'}
      labelYes='Add'
      loadingYes={false}
      cssClass={Style.nomicOrgModal}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>Capability</h2>
        <p>
          Select the capabilities the AI should retrieve knowledge from to <br /> respond to your query.
        </p>
        <RecallDropdown
          chatType={ChatTypeEnum.RECALL}
          items={capabilities}
          onSelectMultipleItems={(items) => setSelectedCapabilities(items as string[])}
          selectedMultipleItems={selectedCapabilities}
          label='Select Capability'
          selectAllItemLabel='Select All Capabilities'
          unselectAllItemLabel='Unselect All Capabilities'
        />
      </div>
    </RecallModal>
  )
}

export default CapabilityModal
