import { FC, useState } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { BambooPropertyEnum } from '../../enums/BambooPropertyEnum'
import { IBambooPropertyModel } from '../../models/IBambooPropertyModel'
import LimitedCharactersLeft from '../common/LimitedCharacters'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import RecallCheckbox from '../common/RecallCheckbox'
import RecallDropdown from '../common/RecallDropdown'
import { v4 } from 'uuid'
import { BambooPropertyModalFields, bambooPropertyModalSchema } from '../../validators/bambooPropertyModalValidator'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import { useLanguages } from '../../hooks/useLanguages'

import Style from '../../styles/BambooPropertyModal.module.sass'

const maxPropNameLength = 500
const maxDescLength = 1000

interface IPropertyModalProps {
  open: boolean
  selectedProperty: IBambooPropertyModel
  properties: IBambooPropertyModel[]
  onSave: (data: IBambooPropertyModel) => void
  onClose: () => void
}

const BambooPropertyModal: FC<IPropertyModalProps> = ({ open, selectedProperty, properties, onSave, onClose }) => {
  const [property, setProperty] = useState<IBambooPropertyModel>({
    ...selectedProperty,
    type: selectedProperty.type ?? BambooPropertyEnum.STRING,
    required: selectedProperty.required ?? true,
  })
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)
  const { t } = useLanguages()

  const handleModalClose = () => {
    setProperty((val) => ({ ...val, name: '', description: '', type: BambooPropertyEnum.STRING, required: false }))
    setErrors({})
    onClose()
  }

  const validateProperty = (currentProperty: IBambooPropertyModel) => {
    const validationResult = bambooPropertyModalSchema({ properties, t }).validate(
      { name: currentProperty.name, description: currentProperty.description },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string | boolean) => {
    let limitedValue = value
    if (typeof value === 'string') {
      if (field === BambooPropertyModalFields.description) {
        limitedValue = value.substring(0, maxDescLength)
      } else if (field === BambooPropertyModalFields.name) {
        limitedValue = value.substring(0, maxPropNameLength)
      }
    }

    const updatedProperty = { ...property, [field]: limitedValue }
    setProperty(updatedProperty)
    const errors = validateProperty(updatedProperty)
    setErrors(errors)
  }

  const handleSaveProperty = () => {
    const errors = validateProperty(property)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    onSave({
      ...property,
      id: property.id || v4(),
      name: property.name.trim(),
    })

    handleModalClose()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.BAMBOO}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!property.name || !!Object.keys(errors).length}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={selectedProperty.id ? t('update') : t('create')}
    >
      <div className={Style.contentWrapper}>
        <h2>{selectedProperty.id ? 'Edit Property' : 'New Property'}</h2>
        <p className={Style.propertyDescription}>{t('property_title2')}</p>

        <Grid row gap={12}>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('property_placeholder')}
              value={property.name}
              onChange={(val) => handleValueChange(BambooPropertyModalFields.name, val)}
              label={t('property_input')}
              error={errors.name?.[0]}
              chatType={ChatTypeEnum.BAMBOO}
            />
            {!errors.name?.[0] && <LimitedCharactersLeft maxLength={maxPropNameLength} value={property.name} />}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              multiline
              placeholder={t('property_desc')}
              value={property.description}
              onChange={(val) => handleValueChange(BambooPropertyModalFields.description, val)}
              cssClass={Style.description}
              label={t('property_input_desc')}
              chatType={ChatTypeEnum.BAMBOO}
            />
            {!errors.description?.[0] && (
              <LimitedCharactersLeft
                maxLength={maxDescLength}
                value={property.description}
                className={Style.limitCharacters}
              />
            )}
          </Grid>
          <Grid item xs={12} cssClass={Style.infoRow}>
            <Icon type='info' cssClass={Style.infoIcon} outlined />
            <span>{t('property_desc_placeholder')}</span>
          </Grid>
          <Grid item xs={12}>
            <div className={Style.typeWrapper}>
              <div className={Style.dropdownWrapper}>
                <RecallDropdown
                  open='up'
                  label={t('property_type')}
                  items={[
                    { id: BambooPropertyEnum.STRING, label: BambooPropertyEnum.STRING },
                    { id: BambooPropertyEnum.NUMBER, label: BambooPropertyEnum.NUMBER },
                    { id: BambooPropertyEnum.BOOLEAN, label: BambooPropertyEnum.BOOLEAN },
                  ]}
                  selectedItem={property.type}
                  onSelectItem={(val) => {
                    handleValueChange(BambooPropertyModalFields.type, val.toString())
                  }}
                  cssClass={Style.type}
                  placeholder={t('select_option')}
                  chatType={ChatTypeEnum.BAMBOO}
                />
              </div>
              <div className={Style.checkboxWrapper}>
                <b>{t('property_required')}</b>
                <RecallCheckbox
                  checked={property.required}
                  onChange={(val) => {
                    handleValueChange(BambooPropertyModalFields.required, val)
                  }}
                  chatType={ChatTypeEnum.BAMBOO}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default BambooPropertyModal
