import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { ResponseData } from '../models/api/IResponse'
import { TokenManager } from './TokenManager'
import { getLoggedUser } from './UserService'

interface IAppInsightsConfig {
  conn: string
  ip: string
}

export const getAIConn = async (): Promise<IAppInsightsConfig | null> => {
  try {
    const response = await fetch('/v1/app/aiconn')
    const result = await response.json()
    return ResponseData<IAppInsightsConfig>(result) || null
  } catch {
    return null
  }
}

const reactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights
let department = ''

getAIConn()
  .then((aiConfig) => {
    console.log('** Starting Application Insights...')

    if (!aiConfig) return

    const browserHistory = createBrowserHistory()
    appInsights = new ApplicationInsights({
      config: {
        connectionString: aiConfig.conn,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    })

    appInsights.loadAppInsights()
  })
  .catch((error: number) => {
    console.log(`** Couldn't start Application Insights. Error: ${error}`)
  })

const createAppInsightContext = async () => {
  if (!appInsights) return

  const tokenMgr = await TokenManager()
  const oid = tokenMgr.getUserOID()

  appInsights.setAuthenticatedUserContext(oid)

  const response = await getLoggedUser({})
  department = response?.data?.department ?? ''

  if (!department) console.warn('There was an issue fetching user department')

  const telemetryInitializer = (envelope: ITelemetryItem) => {
    if (!envelope.data) return
    envelope.data['department'] = department
  }

  appInsights.addTelemetryInitializer(telemetryInitializer)
}

const appInsightsInstance = () => {
  if (!appInsights) return

  return appInsights
}
export { reactPlugin, appInsightsInstance, createAppInsightContext }
