import { HttpContentType } from '../enums/ApiRequestConstants'
import { deleteAsync, getAsync, postAsync, postMultiFormDataAsync, putAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IWinwiseResponseModel } from '../models/api/ICreateChatModels'
import { ICreateWinwiseOppModel } from '../models/api/ICreateWinwiseModel'
import { IResponse } from '../models/api/IResponse'
import {
  IDownloadWinwiseOpportunityFileRequestModel,
  IGetWinwiseOpportunityDetailRequestModel,
  ISubscribeWinwiseOpportunityChatRequestModel,
  ITriggerFileProcess,
  IUpdateWinwiseOpportunityRequestModel,
  IUploadWinwiseOpportunityChunkRequestModel,
  IWinwiseOpportunityDetailModel,
  IWinwiseSuggestedQueriesResponse,
} from '../models/IWinwiseOpportunity'
import { TokenExpiryWrapper } from './TokenManager'

const url = '/v1/winwise'

export const getWinwiseOpportunities = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IWinwiseResponseModel[]>> => getAsync(url, request),
  [],
  null,
)

export const createWinwiseOpportunity = TokenExpiryWrapper(
  (request: ICreateWinwiseOppModel): Promise<IResponse<IWinwiseResponseModel>> => postAsync(url, request),
  [],
  null,
)

export const getWinwiseOpportunityDetail = TokenExpiryWrapper(
  (request: IGetWinwiseOpportunityDetailRequestModel): Promise<IResponse<IWinwiseOpportunityDetailModel>> =>
    getAsync(`${url}/opportunity/${request.opportunityId}`, request),
  [],
  null,
)

export const updateWinwiseOpportunity = TokenExpiryWrapper(
  (request: Partial<IUpdateWinwiseOpportunityRequestModel>): Promise<IResponse<string>> =>
    putAsync(`${url}/opportunity`, request),
  [],
  null,
)

export const archiveOpportunity = TokenExpiryWrapper(
  (request: Partial<IUpdateWinwiseOpportunityRequestModel>): Promise<IResponse<string>> =>
    putAsync(`${url}/opportunity/archive`, request),
  [],
  null,
)

export const uploadWinwiseOpportunityFileChunk = TokenExpiryWrapper(
  async (request: IUploadWinwiseOpportunityChunkRequestModel): Promise<IResponse<string>> => {
    const formData = new FormData()
    formData.append('chunk', request.chunk)
    formData.append('opportunityId', request.opportunityId)
    formData.append('opportunityFileTypeId', request.opportunityFileTypeId)
    formData.append('metadata', JSON.stringify(request.metadata))

    try {
      return await postMultiFormDataAsync(`${url}/opportunity/upload-chunk`, request?.token ?? '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)

export const downloadWinwiseOppoturnityFile = TokenExpiryWrapper(
  async (request: IDownloadWinwiseOpportunityFileRequestModel): Promise<IResponse<Blob>> => {
    const { opportunityId, filename, fileTypeId } = request
    const finalUrl = `${url}/opportunity/${opportunityId}/${fileTypeId}/${filename}`
    return getAsync<IDownloadWinwiseOpportunityFileRequestModel, Blob>(finalUrl, request, HttpContentType.BLOB)
  },
  [],
  null,
)

export const deleteWinwiseOppoturnityFile = TokenExpiryWrapper(
  async (request: IDownloadWinwiseOpportunityFileRequestModel): Promise<IResponse<Blob>> => {
    const { opportunityId, filename, fileTypeId } = request
    const finalUrl = `${url}/opportunity/${opportunityId}/${fileTypeId}/${filename}`
    return deleteAsync<IDownloadWinwiseOpportunityFileRequestModel, Blob>(finalUrl, request, HttpContentType.BLOB)
  },
  [],
  null,
)

export const subscribeWinwiseOpportunityChat = TokenExpiryWrapper(
  (request: ISubscribeWinwiseOpportunityChatRequestModel): Promise<IResponse<boolean>> =>
    postAsync(`${url}/opportunity/chat-subscribe`, request),
  [],
  null,
)

export const getWinwiseSuggestedQueries = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IWinwiseSuggestedQueriesResponse[]>> =>
    getAsync(`${url}/suggested-queries`, request),
  [],
  null,
)

export const triggerProcessFile = TokenExpiryWrapper(
  (request: ITriggerFileProcess): Promise<IResponse<string>> =>
    getAsync(`${url}/opportunity/trigger-files-process/${request.opportunityId}`, request),
  [],
  null,
)

export const updateOpportunityFiles = TokenExpiryWrapper(
  (request: ITriggerFileProcess): Promise<IResponse<string>> =>
    getAsync(`${url}/opportunity/update-opportunity-files/${request.opportunityId}`, request),
  [],
  null,
)
