import { FC, useEffect } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatSession, QuestionFile, QuestionInput } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'
import { useInitializeChatSession } from '../hooks/useInitializeChatSession'

const SUGGESTIONS = ['recall_sample', 'recall_sample2', 'recall_sample3']

const ChatRecallHome: FC = () => {
  const setQuestionInput = useSetRecoilState(QuestionInput)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const { t } = useLanguages()
  const [chatSession, setChatSession] = useRecoilState(ChatSession)

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Recall Chat Home' })

  useInitializeChatSession(ChatTypeEnum.RECALL)
  useEffect(() => {
    if (!chatSession) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setQuestionFile(null)
  }, [setQuestionFile])

  const handleSuggestion = (value: string) => {
    setQuestionInput(value)
  }

  return (
    <Page menu contentWrapper>
      <h1 className={Style.chatTitle}>Recall Chat</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.RECALL} />
      <p>{t('recall_desc')}</p>
      <p>
        <Trans
          i18nKey='recall_hive'
          components={[
            <a
              key={0}
              href='https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/AI-at-Aurecon.aspx'
              target='_blank'
              rel='noreferrer'
              aria-label='Aurecon Hive Link'
            ></a>,
          ]}
        />
      </p>
      <p className={Style.note}>
        <i>{t('recall_desc2')}</i>
      </p>

      <div className={Style.examples}>
        <h3>{t('recall_desc3')}</h3>
        {SUGGESTIONS.map((key) => {
          const suggestion = t(key)
          return (
            <div
              key={suggestion}
              className={Style.question}
              onClick={() => handleSuggestion(suggestion)}
              onKeyDown={() => handleSuggestion(suggestion)}
              role='none'
            >
              <Icon type='format_quote' outlined size='14px' /> {suggestion}
            </div>
          )
        })}
      </div>
      <div className={Style.examples}>
        <h3>{t('navigation_tip')}</h3>
        <Grid row>
          <div>
            <p>
              <Icon type='keyboard' outlined />
            </p>
          </div>
          <div className={Style.tip}>
            <p>
              <Trans i18nKey='navigation' />
            </p>
          </div>
        </Grid>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatRecallHome
