import Joi from 'joi'
import { validateSpecialCharacters } from '../helpers/utils'
import { NomicDataSourceEnum } from '../enums/BambooPropertyEnum'

export const NomicPropertyModalFields = {
  dataSource: 'dataSource',
  appName: 'appName',
  nomicMap: 'nomicMap',
  prompt: 'prompt',
  description: 'description',
}

export const nomicPropertyModalSchema = () => {
  return Joi.object({
    [NomicPropertyModalFields.dataSource]: Joi.number().integer().min(1).max(2).required().messages({
      'number.empty': 'Type selection is required',
      'number.min': 'Type selection must be either existing or new',
      'number.max': 'Type selection must be either existing or new',
    }),
    [NomicPropertyModalFields.appName]: Joi.string()
      .trim()
      .min(1)
      .max(200)
      .required()
      .custom((text, helper) => {
        const validForSpecialChars = validateSpecialCharacters(text)
        if (!validForSpecialChars) {
          return helper.message({ custom: 'Cannot contain special character in App name' })
        }
        return true
      })
      .messages({ 'string.empty': 'Agent Name is required' }),
    [NomicPropertyModalFields.nomicMap]: Joi.when(NomicPropertyModalFields.dataSource, {
      is: NomicDataSourceEnum.EXISTING_NOMIC,
      then: Joi.string()
        .trim()
        .uri()
        .required()
        .messages({ 'string.empty': 'Nomic Map URL is required', 'string.uri': 'Nomic Map must be a valid URL' }),
      otherwise: Joi.any(),
    }),
    [NomicPropertyModalFields.prompt]: Joi.string()
      .trim()
      .min(1)
      .max(10000)
      .required()
      .messages({ 'string.empty': 'System prompt is required' }),
  })
}
