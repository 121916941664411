import { FC, useMemo, useState } from 'react'
import { Button, Grid, IOption, Loader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import Style from '../../styles/ManageAccessModal.module.sass'
import UserComboBox from '../common/UserComboBox'
import { format, parseISO } from 'date-fns'
import { twelveHourFormat } from '../../config/config'
import { sortArrBy } from '../../helpers/utils'
import { useLanguages } from '../../hooks/useLanguages'
import { IGenericModel } from '../../models/api/IGenericModel'

interface IManageAccessModalProps {
  open: boolean
  onSave: (users: IOption[], obj: IGenericModel) => Promise<void>
  onRevoke: (id: string, userId: string, name: string) => Promise<void>
  onClose: () => void
  pickedItem: IGenericModel
  shareList: IGenericModel[]
  chatType: number
  description: string
  loading?: boolean
  loadingTable?: boolean
}

const defaultSortOrder = {
  name: 'none' as sortType,
  updatedAt: 'desc' as sortType,
}

type sortType = 'none' | 'desc' | 'asc' | undefined

interface IManageAccessSortOrder {
  name: sortType
  updatedAt: sortType
}

const ManageAccessModal: FC<IManageAccessModalProps> = (props) => {
  const { open, onClose, loading, pickedItem, shareList, onSave, onRevoke, chatType, description, loadingTable } = props
  const fullScreen = useRecoilValue(FullScreen)
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>([])
  const [deletingUser, setDeletingUser] = useState('')
  const [sortOrder, setSortOrder] = useState<IManageAccessSortOrder>(defaultSortOrder)
  const { t } = useLanguages()

  const handleModalClose = () => {
    onClose()
  }

  const handleAddAccess = async () => {
    await onSave(selectedUsers, pickedItem)
    setSelectedUsers([])
  }

  const handleRevokeAccess = async (id: string, userId: string, name: string) => {
    setDeletingUser(userId)
    await onRevoke(id, userId, name)
    setDeletingUser('')
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      updatedAt: sort === 'none' && field !== 'updatedAt' ? 'desc' : ('none' as sortType),
      [field]: sort,
    }
    setSortOrder(newOrder)
  }

  const filteredAndSortedList = useMemo(() => {
    const foundSortKey = (Object.keys(sortOrder) as [keyof IManageAccessSortOrder]).find(
      (key) => sortOrder[key] === 'asc' || sortOrder[key] === 'desc',
    )

    const sortKey = foundSortKey ?? 'updatedAt'
    const order = sortOrder[sortKey]

    return sortArrBy(order as string, shareList, sortKey) as IGenericModel[]
  }, [shareList, sortOrder])

  const excludedIds =
    chatType !== ChatTypeEnum.WINWISE
      ? [pickedItem.ownerId, ...shareList.map((c) => c.auth0UserId)]
      : [pickedItem.userId, ...shareList.map((c) => c.userId)]

  const renderModalContent = () => {
    return (
      <div className={Style.contentWrapperNomicModal}>
        <h2>{t('manage_access')}</h2>
        <p className={Style.propertyDescriptionManageAccess}>{description}</p>
        <Grid row gap={12}>
          <UserComboBox
            onSelectedItem={setSelectedUsers}
            selectedItems={selectedUsers}
            excludeIds={excludedIds}
            label={t('select_user')}
            placeholder={t('user_email')}
          />
          {loadingTable ? (
            <div className={Style.loadingWrapper}>
              <Loader size='small' />
            </div>
          ) : (
            <Grid item>
              <Table
                breakpoint={300}
                headers={[
                  {
                    label: t('user_list'),
                    sort: sortOrder.name,
                    onSort: (sort) => onSort('name', sort),
                  },
                  {
                    label: t('shared_on'),
                    sort: sortOrder.updatedAt,
                    onSort: (sort) => onSort('updatedAt', sort),
                  },

                  { label: t('action') },
                ]}
                cssClass={Style.manageAccessTable}
              >
                {filteredAndSortedList.map((c) => (
                  <TableRow key={c.auth0UserId}>
                    <TableCell cellClass={Style.cell}>{c.name}</TableCell>
                    <TableCell cellClass={Style.cell}> {format(parseISO(c.updatedAt), twelveHourFormat)}</TableCell>
                    <TableCell cellClass={Style.cell}>
                      <Button
                        icon={deletingUser === c.auth0UserId ? undefined : 'person_remove'}
                        type='icon-round-secondary'
                        onClick={() => handleRevokeAccess(pickedItem.id, c.auth0UserId ?? c.userId, c.name)}
                        loading={deletingUser === c.auth0UserId}
                        cssClass={Style.revokeBtn}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
  return (
    <div className={Style.manageAccessModal}>
      <RecallModal
        showActionButtons
        chatType={chatType}
        isShowing={open}
        onSave={handleAddAccess}
        onClose={handleModalClose}
        disabled={!selectedUsers.length}
        size={fullScreen ? 'medium' : 'small'}
        labelYes={t('share')}
        loadingYes={loading}
      >
        {renderModalContent()}
      </RecallModal>
    </div>
  )
}

export default ManageAccessModal
