import { FC } from 'react'

import Page from '../components/Page'
import ErrorGfx from '../assets/kitten.svg'
import { appInsightsInstance } from '../api/AppInsights'

import Style from '../styles/Style.module.sass'

const NotFound: FC = () => {
  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'NotFound' })

  return (
    <Page menu contentWrapper>
      <div className={Style.errorWrapper}>
        <div className={Style.innerWrapper}>
          <p>
            <img src={ErrorGfx} alt='Page not found' />
          </p>
          <h3>404 - Page Not Found</h3>
        </div>
      </div>
    </Page>
  )
}

export default NotFound
