import React, { FC, useEffect, useContext, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import classNames from 'classnames'
import { Container, Icon, ThemeContext, ThemeEnum } from '@aurecon-creative-technologies/styleguide'

import Menu from './Menu'
import PageHeader from './PageHeader'
import QuestionBox from './QuestionBox'
import {
  BannerInfo,
  ChatType,
  FullScreen,
  Language,
  PageBannerActive,
  ScrollChat,
  TriggerScroll,
} from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/Page.module.sass'
import debounce from 'debounce'
import { useAuth0 } from '@auth0/auth0-react'
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/utils'
import { BANNER_CLOSED_KEY } from '../config/config'
import { useSystemBanner } from '../hooks/useSystemBanner'
import { t } from 'i18next'
import LoadingScreen from './LoadingScreen'
import { useHasPermissions } from '../hooks/useHasPermissions'

export interface IPageProps {
  pageTitle?: string
  menu?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  contentWrapper?: boolean
  contentWrapperWide?: boolean
  noGrow?: boolean
  contentsRef?: React.RefObject<HTMLDivElement>
  cssClassName?: string
}

const PAGE_SCROLL_ADJUSTMNET = 1000

const excludedChatTypes = [ChatTypeEnum.BAMBOO, ChatTypeEnum.WINWISE]

const Page: FC<IPageProps> = (props) => {
  const { isLoading } = useAuth0()
  const { theme } = useContext(ThemeContext)
  const language = useRecoilValue(Language)
  const chatType = useRecoilValue(ChatType)
  const fullScreen = useRecoilValue(FullScreen)
  const scrollChat = useRecoilValue(ScrollChat)
  const [triggerScroll, setTriggerScroll] = useRecoilState(TriggerScroll)
  const [bannerInfo, setBannerInfo] = useRecoilState(BannerInfo)
  const [pageBannerActive, setPageBannerActive] = useRecoilState(PageBannerActive)
  const { systemBanner } = useSystemBanner()
  const bannerRef = useRef<HTMLDivElement>(null)
  const { permissionsLoading } = useHasPermissions()

  useEffect(() => {
    if (!props.contentsRef?.current || !triggerScroll) return

    props.contentsRef.current.scrollTo({
      top: props.contentsRef.current.scrollHeight + PAGE_SCROLL_ADJUSTMNET,
      behavior: 'smooth',
    })
  }, [props.contentsRef, scrollChat, triggerScroll])

  const message = systemBanner?.message

  const pageContentsClasses = classNames({
    [Style.pageContents]: true,
    [Style.fullscreen]: fullScreen,
    [Style.pageWideContents]: props.contentWrapperWide,
    [Style.noGrow]: props.noGrow,
  })

  const pageClasses = classNames({
    [Style.page]: true,
    pageThemeDark: theme === ThemeEnum.DARK,
    pageFontOpenSans: language === 'vi',
  })

  const scrolling = debounce((ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = ev.target as HTMLDivElement
    const elms: HTMLDivElement[] = Array.prototype.slice.call(document.querySelectorAll('[id^=qa-holder-]'))
    if (!elms.length) return
    const pos = target.scrollTop + target.offsetHeight
    const notBottom = elms.some((e) => e.offsetTop < pos && pos < e.offsetTop + e.clientHeight)
    setTriggerScroll(!notBottom)
  }, 500)

  const enableSystemBanner = systemBanner?.enabled && pageBannerActive && !isLoading

  useEffect(() => {
    const bannerDiv = bannerRef.current
    if (!bannerDiv || !enableSystemBanner) return

    setBannerInfo((b) => ({ ...b, height: bannerDiv.offsetHeight + 20 }))
  }, [enableSystemBanner, setBannerInfo])

  const onBannerClose = () => {
    const lastModified = systemBanner?.lastModified
    if (!lastModified) return
    const lastBanner = getLocalStorageItem(BANNER_CLOSED_KEY)
    if (lastBanner !== lastModified) setLocalStorageItem(BANNER_CLOSED_KEY, lastModified)
    setBannerInfo({ height: 0 })
    setPageBannerActive(false)
  }

  const renderSystemBanner = () => {
    return (
      <div className={Style.bannerContainer} ref={bannerRef}>
        <div className={Style.message} dangerouslySetInnerHTML={{ __html: message ?? ' ' }} />
        <div className={Style.iconHolder}>
          <Icon cssClass={Style.icon} type='close' onClick={onBannerClose} />
        </div>
      </div>
    )
  }

  const isExcludedChatType = excludedChatTypes.includes(chatType ?? 0)

  if (permissionsLoading) return <LoadingScreen text={t('access_message')} />

  return (
    <div className={pageClasses}>
      <Container cssClass={Style.container}>
        {enableSystemBanner && renderSystemBanner()}
        <div
          className={Style.columns}
          style={{ paddingTop: enableSystemBanner ? `${bannerInfo.height}px` : undefined }}
        >
          {props.menu && <Menu />}
          <div
            className={classNames(Style.mainPageWrapper, props.cssClassName)}
            ref={props.contentsRef}
            onScrollCapture={scrolling}
          >
            <PageHeader pageTitle={props.pageTitle} />
            {props.contentWrapper ? <div className={pageContentsClasses}>{props.children}</div> : <>{props.children}</>}
          </div>
          {chatType !== null && !isExcludedChatType && <QuestionBox />}
        </div>
      </Container>
    </div>
  )
}

export default Page
