import { useEffect, useState } from 'react'
import { getNomicToken } from '../api/TokenManager'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { useAppConfig } from './useAppConfig'

export const useNomicToken = (chatType: number) => {
  const [nomicToken, setNomicToken] = useState('')
  const [loadingNomicToken, setLoadingNomicToken] = useState(false)

  const config = useAppConfig()

  useEffect(() => {
    const effectAsync = async () => {
      if (!config) {
        setLoadingNomicToken(false)
        return
      }

      if (chatType !== ChatTypeEnum.CUSTOM_RECALL_APP && chatType !== ChatTypeEnum.WINWISE) {
        setNomicToken('')
        setLoadingNomicToken(false)
        return
      }

      setLoadingNomicToken(true)

      const nomicToken = await getNomicToken([], config)
      setNomicToken(nomicToken ?? '')
      setLoadingNomicToken(false)
    }

    effectAsync()
  }, [chatType, config])

  return { loadingNomicToken, nomicToken }
}
