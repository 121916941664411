import { FC } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallButton.module.sass'
import classNames from 'classnames'

export interface IRecallButtonProps {
  chatType: number
  label?: string
  type?: 'primary' | 'secondary' | 'icon-round' | 'custom' | 'text'
  icon?: string
  onClick?: () => void
  cssClass?: string
  disabled?: boolean
  size?: 'extra small' | 'small' | 'medium' | 'large' | 'extra large'
}

const RecallButton: FC<IRecallButtonProps> = ({ chatType, label, type, onClick, cssClass, disabled, icon, size }) => {
  const chatTypeClasses = () => {
    return classNames(cssClass, {
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
      [Style.winwise]: chatType === ChatTypeEnum.WINWISE,
      [Style.custom]: type === 'custom',
      [Style.iconRound]: type === 'icon-round',
      [Style.primary]: type === 'primary',
      [Style.secondary]: type === 'secondary',
      [Style.text]: type === 'text',
    })
  }

  if (type === 'custom')
    return (
      <Button
        type='default'
        icon={icon ?? ''}
        label={label}
        cssClass={chatTypeClasses()}
        onClick={() => onClick?.()}
        disabled={disabled}
      />
    )
  if (icon)
    return (
      <Button
        type={type}
        label={label}
        icon={icon}
        size={size}
        cssClass={chatTypeClasses()}
        onClick={() => onClick?.()}
        disabled={disabled}
      />
    )

  return <Button label={label} cssClass={chatTypeClasses()} onClick={() => onClick?.()} disabled={disabled} />
}

export default RecallButton
