import { getAsync, postAsync, putAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IChatResponseModel } from '../models/api/IChatModels'
import {
  ICreateChatRequestModel,
  ICreateChatResponseModel,
  ICreateWinwiseChatRequestModel,
} from '../models/api/ICreateChatModels'
import { IResponse } from '../models/api/IResponse'
import {
  IUpdateChatRequestModel,
  IUpdateChatResponseModel,
  IUpdateWinWiseChatRequestModel,
} from '../models/api/IUpdateChatModels'
import { TokenExpiryWrapper } from './TokenManager'

export const getChats = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IChatResponseModel[]>> => getAsync('/v1/chat', request),
  [],
  null,
)

export const createChat = TokenExpiryWrapper(
  (request: ICreateChatRequestModel): Promise<IResponse<ICreateChatResponseModel>> => postAsync('/v1/chat', request),
  [],
  null,
)

export const updateChat = TokenExpiryWrapper(
  (request: IUpdateChatRequestModel): Promise<IResponse<IUpdateChatResponseModel>> => putAsync('/v1/chat', request),
  [],
  null,
)

export const createWinwiseOpportunityChat = TokenExpiryWrapper(
  (request: ICreateWinwiseChatRequestModel): Promise<IResponse<ICreateChatResponseModel>> =>
    postAsync('/v1/chat/winwise', request),
  [],
  null,
)

export const updateWinwiseOpportunityChat = TokenExpiryWrapper(
  (request: IUpdateWinWiseChatRequestModel): Promise<IResponse<IUpdateChatResponseModel>> =>
    putAsync('/v1/chat/winwise', request),
  [],
  null,
)
