import { LevelEnum } from '../enums/LevelEnum'
import { Drive, Site } from '@microsoft/microsoft-graph-types'
import { Client, PageCollection } from '@microsoft/microsoft-graph-client'
import jwt_decode from 'jwt-decode'

const msClient = (token: string) =>
  Client.init({
    defaultVersion: 'v1.0',
    debugLogging: true,
    authProvider: (done) => {
      const error = new Error('authError')
      done(error, token)
    },
  })

interface IMSALProps {
  siteId?: string
  driveId?: string
  itemId?: string
  token: string | null
}

export interface IMSGraphFile extends Site {
  type: number
  id: string
  driveId: string
}

export interface IMSGraphDrive extends Drive {
  type: number
  id: string
}

export const GRAPH_URL = 'https://graph.microsoft.com/v1.0'

export const checkTokenExpiration = (tokenString: string) => {
  try {
    if (!tokenString) return true
    //Here we are checking for expiry token which is one possibility when 401 occurs
    const decoded: { exp: string } = jwt_decode(tokenString)
    const now = Date.now() / 1000
    const expiryString = decoded ? decoded.exp : null
    const expiryEpoch = expiryString ? parseInt(expiryString) : now

    return expiryEpoch < now
  } catch (error) {
    console.error("** Can't decode token...")
    console.error(error)
    return false
  }
}

const callGraphApi = async (accessToken: string, path: string) => {
  const client = msClient(accessToken)

  let response: PageCollection = await client.api(path).get()
  let res: PageCollection = { value: response.value }

  while (response.value.length > 0 && res.value.length < 200) {
    if (response['@odata.nextLink']) {
      response = await client.api(response['@odata.nextLink']).get()
      res = { ...res, value: [...res.value, ...response.value] }
    } else {
      break
    }
  }
  return res
}

export const GetSites = async (token: string | null): Promise<IMSGraphFile[]> => {
  const accessToken = token
  if (!accessToken) return []

  const sites = await callGraphApi(accessToken, '/sites?search=*')

  return sites.value.map((site: Site) => ({
    ...site,
    id: site.id || '',
    type: LevelEnum.SITE,
    driveId: '',
  }))
}

export const GetDrives = async (props: IMSALProps): Promise<IMSGraphFile[]> => {
  const { siteId, token } = props

  const accessToken = token
  if (!accessToken) return []

  const drives = await callGraphApi(accessToken, `/sites/${siteId}/drives`)

  return drives.value.map((drive: Drive) => {
    return {
      id: drive.id || '',
      name: drive.name,
      webUrl: drive.webUrl,
      type: LevelEnum.DRIVE,
      lastModifiedDateTime: drive.lastModifiedDateTime,
      driveId: drive.id ?? '',
    }
  })
}

export const GetFilesRoot = async (props: IMSALProps) => {
  const { driveId, token } = props

  const accessToken = token
  if (!accessToken) return []

  const files = await callGraphApi(accessToken, `/drives/${driveId}/root/children`)

  if (!files.value) {
    throw new Error('No files found in the root directory.')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return files.value.map((file: any) => {
    return {
      id: file.id,
      name: file.name,
      webUrl: file.webUrl,
      type: file.folder ? LevelEnum.FOLDER : LevelEnum.FILE,
      lastModifiedDateTime: file.lastModifiedDateTime,
      driveId: driveId ?? '',
    }
  })
}

export const GetFileContent = async (props: IMSALProps) => {
  const { driveId, itemId, token } = props

  const accessToken = token

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const fileContent = await fetch(`${GRAPH_URL}/drives/${driveId}/items/${itemId}/content`, {
    method: 'GET',
    headers,
  })

  const reader = fileContent?.body?.getReader()
  const decoder = new TextDecoder()

  let result = ''

  if (!reader) return result

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    result += decoder.decode(value, { stream: true })
  }

  result += decoder.decode()
  return result
}

export const GetMSFile = async (props: IMSALProps) => {
  const { driveId, itemId, token } = props

  const accessToken = token

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const fileContentUrl = `${GRAPH_URL}/drives/${driveId}/items/${itemId}/content`

  try {
    const response = await fetch(fileContentUrl, {
      method: 'GET',
      headers,
    })

    if (!response.ok) throw Error('Error getting MS Blob')
    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Error fetching file content:', error)
    return null
  }
}

export const GetFilesFolder = async (props: IMSALProps) => {
  const { driveId, itemId, token } = props

  const accessToken = token
  if (!accessToken) return []

  const folderFiles = await callGraphApi(accessToken, `/drives/${driveId}/items/${itemId}/children`)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return folderFiles.value.map((file: any) => {
    return {
      id: file.id,
      name: file.name,
      webUrl: file.webUrl,
      type: file.file ? LevelEnum.FILE : LevelEnum.FOLDER,
      lastModifiedDateTime: file.lastModifiedDateTime,
      driveId: driveId ?? '',
    }
  })
}
