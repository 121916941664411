import { FC, useEffect, useMemo, useState } from 'react'

import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'

import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import AppSlideEditor from '../components/AppSlideEditor'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'
import { IAppSlide } from '../models/api/IHomepageModels'
import { Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { saveAppSlide } from '../api/HomepageService'

import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { AppSlides } from '../stores/AppStore'

import Style from '../styles/HomeAdmin.module.sass'
import SystemBannerEditor from '../components/SystemBannerEditor'
import { auth0Permissions } from '../config/permissions'
import { APP_MINI_CARD_DESCRIPTION_MAX_LENGTH, APP_SLIDE_DESCRIPTION_MAX_LENGTH } from '../config/config'
import { LanguageEnum } from '../enums/LanguageEnum'
import { ResponseData } from '../models/api/IResponse'
import { getTextFromHTML } from '../helpers/utils'
import { useHasPermissions } from '../hooks/useHasPermissions'

const HomeAdmin: FC = () => {
  const { t, i18n } = useLanguages()
  const { addToast } = useToast()
  const refreshAppSlides = useRecoilRefresher_UNSTABLE(AppSlides)

  const { hasPermissions } = useHasPermissions()

  const canEditDashboard = hasPermissions(auth0Permissions.HOMEPAGE_EDIT_ITEM)
  const canAccessBanner = hasPermissions(auth0Permissions.BANNER_EDIT)
  const canEditItems = hasPermissions(auth0Permissions.HOMEPAGE_EDIT_ITEM)

  useEffect(() => {
    const appInsights = appInsightsInstance()
    if (appInsights) appInsights.trackPageView({ name: 'Home Admin' })
  }, [])

  const [dirtySlides, setDirtySlides] = useState<IAppSlide[]>([])

  const onDirty = (dirty: IAppSlide) => {
    setDirtySlides((slides) => {
      const existingIdx = slides.findIndex(
        (slide) =>
          slide.sortOrder === dirty.sortOrder && slide.language === dirty.language && slide.position === dirty.position,
      )
      if (existingIdx !== -1) {
        slides[existingIdx] = dirty
        return [...slides]
      } else {
        return [...slides, dirty]
      }
    })
  }

  const cancelEditing = () => {
    setDirtySlides([])
    refreshAppSlides()
  }

  const saveSlides = async () => {
    const all: boolean[] = []
    const sortedSlides = [...dirtySlides].sort((a, b) => a.language.localeCompare(b.language))

    for (const slide of sortedSlides) {
      const res = ResponseData(await saveAppSlide(slide))
      all.push(res ?? false)
    }

    if (all.every((res) => res)) {
      addToast({
        type: 'success',
        title: t('popup_toast'),
        message: t('save_slides_success'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setDirtySlides([])
      refreshAppSlides()
    } else {
      addToast({
        type: 'error',
        title: t('popup_toast'),
        message: t('save_slides_error'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
    }
  }

  const canSave = useMemo(() => {
    if (!dirtySlides.length || !canEditItems) return false

    return dirtySlides.every((slide) => {
      const hasRequiredFields =
        slide.language === LanguageEnum.ENGLISH ? slide.title && slide.subTitle && slide.descriptionText : true
      const noValidationErrors = !slide.error || Object.keys(slide.error).length === 0

      const descriptionMaxLength =
        slide.position === AppSlidePositionEnum.MAIN
          ? APP_SLIDE_DESCRIPTION_MAX_LENGTH
          : APP_MINI_CARD_DESCRIPTION_MAX_LENGTH

      let isDescriptionValid = slide.descriptionText
        ? getTextFromHTML(slide.descriptionText).length <= descriptionMaxLength
        : false

      if (slide.language !== LanguageEnum.ENGLISH) isDescriptionValid = true

      return !!hasRequiredFields && isDescriptionValid && noValidationErrors
    })
  }, [canEditItems, dirtySlides])

  const onDelete = (deletedSlide: IAppSlide) => {
    setDirtySlides((slides) =>
      slides.filter(
        (slide) =>
          !(
            slide.sortOrder === deletedSlide.sortOrder &&
            slide.language === deletedSlide.language &&
            slide.position === deletedSlide.position
          ),
      ),
    )
  }

  if (!canAccessBanner && !canEditDashboard) {
    location.hash = `#/`
    return null
  }

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      {canEditDashboard && (
        <>
          <h1 className={Style.homeAdminTitle}>{t('homeadmin_header')}</h1>
          <div className={Style.main}>
            <AppSlideEditor position={AppSlidePositionEnum.MAIN} onDirty={onDirty} onDelete={onDelete} />
          </div>
          <div className={Style.small}>
            <AppSlideEditor position={AppSlidePositionEnum.LEFT} onDirty={onDirty} onDelete={onDelete} />
            <AppSlideEditor position={AppSlidePositionEnum.RIGHT} onDirty={onDirty} onDelete={onDelete} />
          </div>
          <div className={Style.footer}>
            <div>
              <Button
                label='Cancel'
                size='medium'
                type='secondary'
                default
                disabled={dirtySlides.length === 0}
                onClick={() => cancelEditing()}
                cssClass={Style.cancelEditSlides}
              />
              <Button label='Save' size='medium' type='primary' disabled={!canSave} onClick={saveSlides} />
            </div>
          </div>
        </>
      )}
      {canAccessBanner && (
        <div className={Style.banner}>
          <SystemBannerEditor />
        </div>
      )}
    </Page>
  )
}

export default HomeAdmin
