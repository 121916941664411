import { FC, Fragment, useEffect, useState } from 'react'
import { Checkbox, Grid, Icon, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { NomicDataSourceEnum } from '../../enums/BambooPropertyEnum'
import LimitedCharactersLeft from '../common/LimitedCharacters'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import RecallDropdown from '../common/RecallDropdown'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/ManageAccessModal.module.sass'
import { INomicModel } from '../../models/INomicModel'
import { NomicPropertyModalFields, nomicPropertyModalSchema } from '../../validators/nomicPropertyModalValidator'
import { DefaultNomicProperty } from '../../pages/CustomRecallAppHome'

import { ReactComponent as NomicImageLeft } from '../../assets/nomic_1.svg'
import { ReactComponent as NomicImageRight } from '../../assets/nomic_2.svg'
import { useLanguages } from '../../hooks/useLanguages'
import { Trans } from 'react-i18next'
import { useUserHasPermissions } from '../../hooks/useUserHasPermissions'
import { actions } from '../../config/permissions'
import { useShowFeature } from '../../hooks/useShowFeature'
import { FeatureFlagEnum } from '../../enums/FeatureFlagEnum'

interface INomicModalProps {
  open: boolean
  nomicPropertyModel: INomicModel
  onNextCallback: (nomic: INomicModel) => void
  onSave: (data: INomicModel) => Promise<void>
  onClose: () => void
  openSharePointModal: () => void
  loading?: boolean
}

const NomicModal: FC<INomicModalProps> = (props) => {
  const { open, nomicPropertyModel, onSave, onClose, loading, openSharePointModal, onNextCallback } = props
  const [nomicProperty, setNomicProperty] = useState<INomicModel>({
    ...nomicPropertyModel,
    dataSource: NomicDataSourceEnum.EXISTING_NOMIC,
  })
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)
  const { t } = useLanguages()
  const canCreateNomicMap = useUserHasPermissions(actions.NOMIC_MAP_CREATOR)
  const showSharepointPicker = useShowFeature(FeatureFlagEnum.ShowSharePointFilePicker)
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false)
  const [hasFormChanges, setHasFormChanges] = useState<boolean>(false)

  useEffect(() => {
    if (nomicPropertyModel.prompt || nomicPropertyModel.description) setShowAdvancedSettings(true)
  }, [nomicPropertyModel.description, nomicPropertyModel.prompt])

  const appNameItems = () => {
    const items = [{ id: NomicDataSourceEnum.EXISTING_NOMIC, label: t('exist_map') }]
    if (canCreateNomicMap && showSharepointPicker.enabled)
      items.push({ id: NomicDataSourceEnum.NEW_NOMIC_MAP, label: 'Create a new Nomic map' })
    return items
  }

  const handleModalClose = () => {
    setNomicProperty(DefaultNomicProperty)
    setErrors({})
    setHasFormChanges(false)
    onClose()
  }

  const validateNomicProperty = (currentProperty: INomicModel) => {
    const validationResult = nomicPropertyModalSchema().validate(
      {
        dataSource: currentProperty.dataSource,
        appName: currentProperty.appName,
        nomicMap: currentProperty.nomicMap,
        prompt: currentProperty.prompt,
      },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string | number, limit?: number) => {
    let val = typeof value === 'string' ? value.substring(0, limit) : value

    if (field === NomicPropertyModalFields.nomicMap && typeof val === 'string') {
      const hashIndex = val.indexOf('#')
      val = hashIndex !== -1 ? val.slice(0, hashIndex) : val
    }

    const updatedProperty = { ...nomicProperty, [field]: val }
    setNomicProperty(updatedProperty)
    const errors = validateNomicProperty(updatedProperty)
    setErrors(errors)

    setHasFormChanges(true)
  }

  const handleSaveProperty = async () => {
    const errors = validateNomicProperty(nomicProperty)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    await onSave({
      ...nomicProperty,
      id: nomicProperty.id,
      prompt: showAdvancedSettings ? nomicProperty.prompt : null,
      description: showAdvancedSettings ? nomicProperty.description : null,
    })

    handleModalClose()
  }

  const labelYes = () => {
    if (nomicProperty.dataSource === NomicDataSourceEnum.NEW_NOMIC_MAP) return 'Next'
    return nomicProperty.id ? t('update') : t('create')
  }

  const onYesClicked = () => {
    if (nomicProperty.dataSource === NomicDataSourceEnum.NEW_NOMIC_MAP) {
      onNextCallback(nomicProperty)
      openSharePointModal()
      return
    }
    handleSaveProperty()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={onYesClicked}
      onClose={handleModalClose}
      disabled={!!Object.keys(errors).length || !hasFormChanges}
      size={fullScreen ? 'large' : 'medium'}
      labelYes={labelYes()}
      loadingYes={loading}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>{nomicProperty.id ? t('update') : t('create')}</h2>
        <p className={Style.propertyDescriptionNomicModal}>
          {t('create_desc')}{' '}
          <a href='https://aurecon-atlas.nomic.ai/' target='_blank' rel='noopener noreferrer'>
            Nomic Atlas
          </a>
        </p>
        <Grid row gap={12}>
          <Grid item xs={12} cssClass={Style.typeDropdownHolder}>
            <RecallDropdown
              open='down'
              label={'Type'}
              items={appNameItems()}
              selectedItem={nomicProperty.dataSource}
              onSelectItem={(val) => {
                handleValueChange(NomicPropertyModalFields.dataSource, val)
              }}
              cssClass={Style.type}
              placeholder={t('select_option')}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {errors.dataSource?.[0] && <div className={Style.dropdownError}>{errors.dataSource[0]}</div>}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('enter_name')}
              value={nomicProperty.appName}
              onChange={(val) => handleValueChange(NomicPropertyModalFields.appName, val, 200)}
              cssClass={Style.description}
              label={t('app_name')}
              error={errors.appName?.[0]}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {!errors.appName?.[0] && <LimitedCharactersLeft maxLength={200} value={nomicProperty.appName} />}
          </Grid>
          {nomicProperty.dataSource === NomicDataSourceEnum.EXISTING_NOMIC && (
            <Grid item xs={12}>
              <RecallFormInput
                required
                placeholder={t('map_url')}
                value={nomicProperty.nomicMap}
                onChange={(val) => handleValueChange(NomicPropertyModalFields.nomicMap, val)}
                cssClass={Style.description}
                label={t('nomic_map')}
                chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
                error={errors.nomicMap?.[0]}
              />
            </Grid>
          )}
          <Grid item xs={12} cssClass={Style.infoRowNomicModal}>
            <Icon type='lightbulb' cssClass={Style.infoIcon} outlined />
            <span>
              <Trans i18nKey='create_desc2' components={[<b key={0} />, <b key={1} />]} />{' '}
            </span>
          </Grid>
          <Grid item xs={12} cssClass={Style.instructions}>
            <NomicImageLeft />
            <Icon type='arrow_circle_right' outlined={true} />
            <NomicImageRight />
          </Grid>
          <Grid item xs={12} cssClass={Style.settingsCheckbox}>
            <Checkbox
              label='Advanced Feature'
              checked={showAdvancedSettings}
              onChange={() => setShowAdvancedSettings(!showAdvancedSettings)}
            />
          </Grid>
          {showAdvancedSettings && (
            <Fragment>
              <Grid item xs={12}>
                <label className={Style.labelContainer}>
                  <span className={Style.labelWithIcon}>System Prompt</span>
                  <span className={Style.inputRequired}>*</span>
                  <Tooltip
                    show={`A System Prompt is a specific instruction or cue provided to the AI model, guiding it on how to generate a response or complete a task. This prompt sets the context and tone for the output, influencing the model's behaviour and the nature of the generated content. For example, a system prompt can outline the type of information required, the desired format, or even stylistic preferences, enabling the AI to align its responses with user expectations and intent. This must align with Aurecon’s policies, as well as Azure OpenAI’s content management policy. `}
                  >
                    <Icon type='info' outlined className={Style.infoIcon} />
                  </Tooltip>
                </label>
                <RecallFormInput
                  required
                  cssClass={Style.description}
                  chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
                  value={nomicProperty.prompt ?? ''}
                  onChange={(val) => handleValueChange(NomicPropertyModalFields.prompt, val, 10000)}
                  multilineLimit={10000}
                  multiline
                  error={errors.prompt?.[0]}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={Style.labelContainer}>
                  <span className={Style.labelWithIcon}>App Description</span>
                  <Tooltip
                    show={`This is the description of your application displayed to users when they begin a new conversation with the app. Give users information on the knowledge the App has access to, or even the way that the app will respond`}
                  >
                    <Icon type='info' outlined className={Style.infoIcon} />
                  </Tooltip>
                </label>
                <RecallFormInput
                  required
                  cssClass={Style.description}
                  chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
                  value={nomicProperty.description ?? ''}
                  onChange={(val) => handleValueChange(NomicPropertyModalFields.description, val, 2000)}
                  multilineLimit={2000}
                  multiline
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </div>
    </RecallModal>
  )
}

export default NomicModal
