import { FC } from 'react'
import Style from '../styles/SuggestedQueries.module.sass'
import { CheckboxGroup, Checkbox } from '@aurecon-creative-technologies/styleguide'
import { useLanguages } from '../hooks/useLanguages'

interface ISuggestedQueriesProps {
  suggestedQueries: ISuggestedQueries[]
  onSelectQuery: (id: number) => void
}

export interface ISuggestedQueries {
  checked: boolean
  id: number
  labelId: string
  disabled: boolean
  selectAll: boolean
}

const SuggestedQueries: FC<ISuggestedQueriesProps> = (props) => {
  const { suggestedQueries, onSelectQuery } = props
  const { t } = useLanguages()

  return (
    <div className={Style.suggestedQueriesLayout}>
      <div className={Style.suggestedQueriesContent}>
        <CheckboxGroup label={t('suggested_queries')}>
          {suggestedQueries.map((query) => {
            const { id, labelId, checked, disabled } = query

            return (
              <Checkbox
                key={id}
                label={t(labelId)}
                checked={checked}
                onChange={() => onSelectQuery(id)}
                disabled={disabled}
              />
            )
          })}
        </CheckboxGroup>
      </div>
    </div>
  )
}

export default SuggestedQueries
