import { FC, useCallback, useEffect, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { IErrorModel } from '../../validators/commonValidator'
import { GetDrives, GetFilesFolder, GetFilesRoot, GetSites, IMSGraphFile } from '../../api/MSGraphService'
import { LevelEnum } from '../../enums/LevelEnum'
import TableFileBrowser from './TableFileBrowser'
import { useMsalInfo } from '../../hooks/useMsalInfo'
import { ISharepointFileModel } from '../../models/INomicModel'

interface ISharePointModalProps {
  open: boolean
  onClose: () => void
  onNext: (files: ISharepointFileModel[]) => void
  selectedSharepointFiles?: ISharepointFileModel[]
}

const LEVEL_UP = {
  id: '..',
  name: '..',
  type: LevelEnum.UP,
  lastModifiedDateTime: 'N/A',
  driveId: '',
}

const SharePointModal: FC<ISharePointModalProps> = (props) => {
  const { open, onClose, onNext, selectedSharepointFiles } = props
  const [tableFiles, setTableFiles] = useState<IMSGraphFile[]>([])
  const [tableLocations, setTableLocations] = useState<IMSGraphFile[]>([])
  const [selectedFiles, setSelectedFiles] = useState<ISharepointFileModel[]>(selectedSharepointFiles ?? [])
  const [loading, setLoading] = useState(false)
  const [sharepointToken, setSharepointToken] = useState('')

  const [errors, setErrors] = useState<IErrorModel>({})
  const { getToken } = useMsalInfo()

  const handleModalClose = () => {
    setErrors({})
    onClose()
  }

  const handleSaveProperty = () => {
    onNext(selectedFiles)
    handleModalClose()
  }

  useEffect(() => {
    if (sharepointToken) return

    const fechtSharepointToken = async () => {
      const token = await getToken()
      setSharepointToken(token)
    }

    fechtSharepointToken()
  }, [getToken, sharepointToken])

  const loadSitesForTable = useCallback(async () => {
    setLoading(true)
    const files = await GetSites(sharepointToken)
    setTableFiles(files)
    setTableLocations([])
    setLoading(false)
  }, [sharepointToken])

  useEffect(() => {
    if (!sharepointToken) return
    loadSitesForTable()
  }, [loadSitesForTable, sharepointToken])

  const loadDrivesForTable = async (siteId: string) => {
    const drives = await GetDrives({ siteId, token: sharepointToken })
    setTableFiles([LEVEL_UP, ...drives])
  }

  const loadRootForTable = async (driveId: string) => {
    const files = await GetFilesRoot({
      driveId,
      token: sharepointToken,
    })

    setTableFiles([LEVEL_UP, ...files])
  }

  const findDriveId = (locs: IMSGraphFile[]) => {
    const drive = locs.find((loc) => loc.type === LevelEnum.DRIVE)
    return drive?.id ?? null
  }

  const findSiteId = (locs: IMSGraphFile[]) => {
    const site = locs.find((loc) => loc.type === LevelEnum.SITE)
    return site?.id ?? null
  }

  const loadFolderForTable = async (itemId: string) => {
    const driveId = findDriveId(tableLocations)
    const files = await GetFilesFolder({
      driveId: driveId ?? '',
      itemId,
      token: sharepointToken,
    })
    setTableFiles([LEVEL_UP, ...files])
  }

  const tableRowClickActions = async (item: IMSGraphFile) => {
    let locs = [...tableLocations]
    let oldItem: IMSGraphFile | undefined = undefined,
      currentItem: IMSGraphFile | undefined = undefined

    const actions = {
      [LevelEnum.SITE]: async () => loadDrivesForTable(item.id),
      [LevelEnum.DRIVE]: async () => loadRootForTable(item.id),
      [LevelEnum.FOLDER]: async () => loadFolderForTable(item.id),
    }

    if ([LevelEnum.SITE, LevelEnum.DRIVE, LevelEnum.FOLDER].includes(item.type)) {
      await actions[item.type]()
      locs = [item]
      return { locs, oldItem, currentItem }
    }

    oldItem = locs.pop()
    currentItem = locs[locs.length - 1]
    return { locs, oldItem, currentItem }
  }

  const oldItemActions = (currentItem: IMSGraphFile, oldItemType: number) => {
    const actions = {
      [LevelEnum.SITE]: async () => {
        await loadSitesForTable()
      },
      [LevelEnum.DRIVE]: async () => {
        const siteId = findSiteId(tableLocations) ?? ''
        await loadDrivesForTable(siteId)
      },
      [LevelEnum.FOLDER]: async () => {
        const driveId = findDriveId(tableLocations) ?? ''
        currentItem?.type === LevelEnum.DRIVE
          ? await loadRootForTable(driveId)
          : await loadFolderForTable(currentItem.id)
      },
    }

    return actions[oldItemType]
  }

  const onTableBreadcrumbClick = async (index: number) => {
    setLoading(true)
    if (!tableLocations) return
    const newLocations = tableLocations.slice(0, index + 1)
    setTableLocations(newLocations)

    if (index === -1) {
      await loadSitesForTable()
      return
    }

    const currentItem = newLocations[newLocations.length - 1]

    await tableRowClickActions(currentItem)
    setLoading(false)
  }

  const onTableRowClick = async (item: IMSGraphFile) => {
    setLoading(true)
    const locObj = await tableRowClickActions(item)
    if (!locObj) return

    setTableLocations(locObj.locs)
    setLoading(false)

    if (item.type !== LevelEnum.UP) return

    const oldItem = locObj.oldItem
    const currentItem = locObj.currentItem

    if (!oldItem || !currentItem) return

    await oldItemActions(currentItem, oldItem.type)()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!!Object.keys(errors).length}
      size='large'
      labelYes='Next'
    >
      <TableFileBrowser
        locations={tableLocations}
        files={tableFiles}
        onRowClick={onTableRowClick}
        onSelect={(files: ISharepointFileModel[]) => setSelectedFiles(files)}
        onBreadcrumbClick={onTableBreadcrumbClick}
        onCloseModal={handleModalClose}
        driveId={findDriveId(tableLocations)}
        loading={loading}
        selectedSharepointFiles={selectedSharepointFiles}
      />
    </RecallModal>
  )
}

export default SharePointModal
