import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Style from '../../styles/components/winwise/WinwiseOpportunityThread.module.sass'
import { IWinwiseOpportunityDetailModel, IWinwiseOpportunityThreadModel } from '../../models/IWinwiseOpportunity'
import {
  Accordion,
  AccordionPanel,
  Badge,
  Grid,
  Icon,
  Loader,
  Modal,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide'
import {
  WinwiseOpportunityDocumentTypeEnum,
  WinwiseOpportunityDocumentOptions,
  GdpDataProcessingState,
} from '../../enums/WinwiseOpportunityEnum'
import QuestionBox from '../QuestionBox'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  ChatSession,
  EnabledChat,
  NotFound,
  ScrollChat,
  WinwiseSelectedQueries,
  WinwiseSuggestedQueries,
} from '../../stores/AppStore'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import ChatSwitcher from '../ChatSwitcher'
import { Trans } from 'react-i18next'
import { useLanguages } from '../../hooks/useLanguages'
import RecallFileTypeIcon from '../common/RecallFileTypeIcon'
import RecallButton from '../common/RecallButton'
import { useNavigate, useParams } from 'react-router-dom'
import ChatQuestion from '../ChatQuestion'
import ChatAnswer from '../ChatAnswer'
import { getWinwiseOpportunityChatQuestions } from '../../api/QuestionService'
import { format, parseISO } from 'date-fns'
import { twelveHourFormat } from '../../config/config'
import RecallResizeWrapper from '../common/RecallResizeWrapper'
import { useInitializeChatSession } from '../../hooks/useInitializeChatSession'
import { AppRoute } from '../../enums/AppRouteConstants'
import ChatNotFound from '../ChatNotFound'
import PreparingChatModal from '../modals/PreparingChatModal'
import { ICreateChatResponseModel } from '../../models/api/ICreateChatModels'
import classNames from 'classnames'
import WinwiseOpportunityChatSubscribe from './WinwiseOpportunityChatSubscribe'
import LoadingScreen from '../LoadingScreen'
import { IQuestion } from '../../models/IQuestionModels'
import SuggestedQueries, { ISuggestedQueries } from '../SuggestedQueries'
import WinwiseManageFilesModal from '../modals/WinwiseManageFilesModal'
import { getWinwiseOpportunityDetail, updateOpportunityFiles } from '../../api/winwiseService'
import { useNomicToken } from '../../hooks/useNomicToken'
import { useAuth0 } from '@auth0/auth0-react'
import NotificationModal from '../modals/NotificationModal'

interface IWinwiseOpportunityThreadProps {
  opportunity: IWinwiseOpportunityDetailModel
  goBack: () => void
  onCreateNewThread: () => void
  onOpportunityChange: (opportunity: IWinwiseOpportunityDetailModel) => void
}

const WinwiseOpportunityThread: FC<IWinwiseOpportunityThreadProps> = (props) => {
  const { opportunity, goBack, onCreateNewThread, onOpportunityChange } = props
  const { t } = useLanguages()
  const { chatId } = useParams()
  const navigate = useNavigate()

  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [scrollChat, setScrollChat] = useRecoilState(ScrollChat)
  const setWinwiseSelectedQueries = useSetRecoilState(WinwiseSelectedQueries)
  const suggestedQueriesLoader = useRecoilValueLoadable(WinwiseSuggestedQueries)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [showManageFilesModal, setShowManageFilesModal] = useState(false)

  const [selectedThread, setSelectedThread] = useState('')
  const [selectedPanelIds, setSelectedPanelIds] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [height, setHeight] = useState(300)
  const setEnabledChat = useSetRecoilState(EnabledChat)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [suggestedQueries, setSuggestedQueries] = useState<ISuggestedQueries[]>([])
  const [hasFileChanges, setHasFileChanges] = useState<boolean>(false)
  const [isUpdatingFiles, setIsUpdatingFiles] = useState<boolean>(false)
  const [isFetchingOpportunity, setIsFetchingOpportunity] = useState(false)

  const { nomicToken } = useNomicToken(ChatTypeEnum.WINWISE)
  const { user } = useAuth0()

  const getSessionAsync = useCallback(async () => {
    const id = chatId ?? ''

    const request = {
      chatId: id,
      opportunityId: opportunity.id,
    }
    const questions = await getWinwiseOpportunityChatQuestions(request)

    const questionsData = questions?.data
    if (!questionsData) {
      setLoading(false)
      setWinwiseSelectedQueries([])
      setNotFound(true)
      setChatSession(null)
      return
    }

    const thread = opportunity.threads.find((t) => t.id === id)
    setChatSession({
      chatId: id,
      inShared: thread?.inShare,
      questions: questionsData,
      type: ChatTypeEnum.WINWISE,
    })
    setEnabledChat(questionsData.every((q) => !q.submitting))
    setLoading(false)
    setWinwiseSelectedQueries([])
    setNotFound(false)

    setTimeout(() => {
      setScrollChat((s) => s + 1)
    }, 1000)
  }, [
    chatId,
    opportunity.id,
    opportunity.threads,
    setChatSession,
    setEnabledChat,
    setWinwiseSelectedQueries,
    setNotFound,
    setScrollChat,
  ])

  useEffect(() => {
    if (!contentsRef.current) return

    contentsRef.current.scrollTo({ top: contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [scrollChat])

  useEffect(() => {
    setSelectedThread(chatId ?? '')
    if (!chatId || chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, getSessionAsync, setSelectedThread])

  const oppHasRfpAndResponseDraft = useMemo(() => {
    const files = opportunity.uploadedFiles
    return (
      files.some((f) => f.fileTypeId === WinwiseOpportunityDocumentTypeEnum.RFP_DOCUMENT) &&
      files.some((f) => f.fileTypeId === WinwiseOpportunityDocumentTypeEnum.RESPONSE_DRAFT)
    )
  }, [opportunity.uploadedFiles])

  useEffect(() => {
    if (suggestedQueriesLoader.state !== 'hasValue' || !suggestedQueriesLoader.contents) return

    let data = suggestedQueriesLoader.contents.map((item) => {
      return {
        id: Number(item.id),
        labelId: item.labelId,
        checked: false,
        disabled: false,
        selectAll: item.selectAll,
        needsResponseDraft: item.needsResponseDraft,
      }
    })

    data = oppHasRfpAndResponseDraft
      ? data.filter((d) => d.needsResponseDraft)
      : data.filter((d) => !d.needsResponseDraft)

    setSuggestedQueries(data)
  }, [oppHasRfpAndResponseDraft, suggestedQueriesLoader.contents, suggestedQueriesLoader.state])

  useEffect(() => {
    if (chatSession?.questions.some((q) => q.submitting)) {
      if (isExpanded) setIsExpanded(false)
      if (suggestedQueries.some((q) => q.checked))
        setSuggestedQueries(suggestedQueries.map((q) => ({ ...q, checked: false, disabled: false })))
    }
  }, [chatSession?.questions, isExpanded, suggestedQueries])

  useInitializeChatSession(ChatTypeEnum.WINWISE)

  const onSubmitNewChat = (chat: ICreateChatResponseModel) => {
    const { id, firstQuestion, inShare } = chat
    const createdThread: IWinwiseOpportunityThreadModel = {
      id,
      inShare,
      question: firstQuestion,
    }
    const threads = [...opportunity.threads, createdThread]
    setSelectedThread(id)
    setSuggestedQueries(suggestedQueries.map((q) => ({ ...q, checked: false, disabled: false })))
    setIsExpanded(false)
    onOpportunityChange({ ...opportunity, threads })
  }

  const getHandyLinks = (): string[] => (opportunity.handyLinks ? JSON.parse(opportunity.handyLinks) : [])

  const togglePanel = (panelId: string) => {
    const heightPerItem = 50

    const isExisting = selectedPanelIds.includes(panelId)
    const panelIds = isExisting ? selectedPanelIds.filter((f) => f !== panelId) : [...selectedPanelIds, panelId]

    const typeId = Number(panelId)
    const itemsOfSession =
      typeId === WinwiseOpportunityDocumentTypeEnum.HANDY_LINKS
        ? getHandyLinks().length
        : opportunity.uploadedFiles.filter((f) => f.fileTypeId === typeId).length

    const heightOfSession = itemsOfSession * heightPerItem || heightPerItem
    setHeight(isExisting ? height - heightOfSession : height + heightOfSession)
    setSelectedPanelIds(panelIds)
  }

  const onClickHandyLink = (link: string) => {
    window.open(link, '_blank')
  }

  const handleSuggestedQuery = (id: number) => {
    const selectAllId = suggestedQueries.find((item) => item.selectAll)
    const data = suggestedQueries.map((item) => {
      if (id === selectAllId?.id) {
        return item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, disabled: !item.disabled, checked: false }
      }
      return item.id === id ? { ...item, checked: !item.checked } : { ...item, disabled: false }
    })

    const selectedQuestions = data.filter((item) => item.checked).map((item) => item.id)
    setWinwiseSelectedQueries(selectedQuestions)
    setSuggestedQueries(data)
  }

  const renderCollaborator = () => {
    return (
      <div className={Style.collaborator}>
        <div className={Style.badgeWrap}>
          <Badge cssClass={Style.badge} limit={2} type='number' value={0}>
            <Icon size='32px' type='perm_identity' />
          </Badge>

          <span className={Style.text}>Collaborator</span>
        </div>

        <Icon size='32px' className={Style.settingIcon} outlined type='settings' />
      </div>
    )
  }

  const renderHandyLinks = () => {
    return getHandyLinks().map((link, idx) => {
      return (
        <Tooltip key={`${link}-${idx}`} show={<div className={Style.linkTooltip}>{link}</div>}>
          <div className={Style.linkWrap} role='none' onClick={() => onClickHandyLink(link)}>
            <Icon size='22px' outlined type='link' className={Style.linkIcon} />
            <span>{link}</span>
          </div>
        </Tooltip>
      )
    })
  }

  const onThreadHistory = (threadId: string) => {
    setSelectedThread(threadId)
    navigate(`/${AppRoute.WINWISE_THREAD}/${opportunity.id}/${threadId}`, { replace: true })
  }

  const fetchOpportunityDetail = async () => {
    setIsFetchingOpportunity(true)
    const response = await getWinwiseOpportunityDetail({ opportunityId: opportunity.id })
    if (response?.data) onOpportunityChange(response.data)
    setIsFetchingOpportunity(false)
  }

  const onManageFilesClick = async () => {
    await fetchOpportunityDetail()
    setShowManageFilesModal(true)
  }

  const renderManageFiles = () => {
    return (
      <div className={Style.manageFiles}>
        <div className={Style.heading}>
          <h3>Files</h3>

          <RecallButton
            chatType={ChatTypeEnum.WINWISE}
            type='secondary'
            label={t('manage_files')}
            disabled={opportunity.archived}
            onClick={onManageFilesClick}
          />
        </div>

        <div className={Style.uploadOptionWrapper}>
          <Accordion cssClass={Style.accordionWrap} activePanelIds={selectedPanelIds} onPanelToggle={togglePanel}>
            {WinwiseOpportunityDocumentOptions.map((uploadOption) => {
              const { label, id } = uploadOption

              const validFiles = opportunity?.uploadedFiles?.filter((f) => f.fileTypeId === id) || []

              return (
                <AccordionPanel key={id} panelId={id.toString()} label={label}>
                  {id === WinwiseOpportunityDocumentTypeEnum.HANDY_LINKS
                    ? renderHandyLinks()
                    : validFiles.map((file) => {
                        const { id, name, contentType, uploadedByUserDisplayName: uploadedBy, lastModified } = file

                        return (
                          <Tooltip
                            key={id}
                            cssClass={Style.tooltipWrap}
                            show={
                              <div className={Style.tooltipMessage}>
                                <div>{name}</div>
                                <div>{format(parseISO(lastModified), twelveHourFormat)}</div>
                                <div>{uploadedBy}</div>
                              </div>
                            }
                          >
                            <div className={Style.filenameWrap}>
                              <RecallFileTypeIcon fileMimeType={contentType} />
                              <span>{name}</span>
                            </div>
                          </Tooltip>
                        )
                      })}
                </AccordionPanel>
              )
            })}
          </Accordion>
        </div>
      </div>
    )
  }

  const renderThreadHistory = () => {
    return (
      <div className={Style.threadHistory}>
        <h3>{t('thread_history')}</h3>

        <div className={Style.listThread}>
          {opportunity.threads.map((thread) => {
            const { id, question, inShare } = thread

            return (
              <Tooltip key={id} show={<div className={Style.linkTooltip}>{question}</div>}>
                <div
                  className={classNames(Style.threadItem, { [Style.active]: id === selectedThread })}
                  role='none'
                  onClick={() => onThreadHistory(id)}
                >
                  <Icon size='24px' outlined type={inShare ? 'groups_outlined' : 'lock_person_outlined'} />
                  <span className={Style.name}>{question}</span>
                </div>
              </Tooltip>
            )
          })}
        </div>

        <RecallButton
          chatType={ChatTypeEnum.WINWISE}
          icon='add'
          label={t('new_thread')}
          type='text'
          cssClass={Style.newThreadBtn}
          onClick={onCreateNewThread}
          disabled={opportunity.archived}
        />
      </div>
    )
  }

  const renderSidebar = () => {
    return (
      <div className={Style.sidebarWrap}>
        {renderCollaborator()}
        <RecallResizeWrapper
          height={height}
          firstComponent={renderManageFiles()}
          secondComponent={renderThreadHistory()}
          cssClass={Style.resize}
        />
      </div>
    )
  }

  const renderChatSubscribe = (isSubscribed: boolean) => {
    return (
      <WinwiseOpportunityChatSubscribe opportunityId={opportunity.id} chatId={chatId ?? ''} subcribed={isSubscribed} />
    )
  }

  const renderChatItems = () => {
    if (chatSession?.questions.some((q) => q.submitting))
      return renderChatSubscribe(chatSession?.questions[0]?.isSubscribed)

    return chatSession?.questions.map((question, index) => {
      const lastQuestion = chatSession?.questions.length - 1 === index
      return (
        <div key={question.rowKey} id={`qa-holder-${question.rowKey}`}>
          <ChatQuestion question={question} cssClass={classNames({ [Style.waitResponseQuestion]: lastQuestion })} />
          {renderAnswer(question, lastQuestion)}
        </div>
      )
    })
  }

  const renderAnswer = (question: IQuestion, lastQuestion: boolean) => {
    if (!lastQuestion || !question.submitting) return <ChatAnswer question={question} />

    return renderChatSubscribe(question?.isSubscribed)
  }

  const renderConversation = () => {
    if (loading) return <LoadingScreen text={t('loading_sessions')} chatType={ChatTypeEnum.WINWISE} />

    return chatSession && chatId === chatSession.chatId ? (
      <div className={Style.chatResponseWrap}>{notFound ? <ChatNotFound /> : renderChatItems()}</div>
    ) : (
      <div>
        <h3>{t('navigation_tip')}</h3>
        <Grid row>
          <div>
            <p>
              <Icon type='keyboard' outlined />
            </p>
          </div>
          <div className={Style.tip}>
            <p>
              <Trans i18nKey='navigation' />
            </p>
          </div>
        </Grid>
      </div>
    )
  }

  const renderSuggestedQueries = () => {
    return (
      isExpanded &&
      !!suggestedQueries.length &&
      !chatSession?.questions.some((q) => q.submitting || q.loading) &&
      !loading && <SuggestedQueries suggestedQueries={suggestedQueries} onSelectQuery={handleSuggestedQuery} />
    )
  }

  const renderChatSession = () => {
    return (
      <div className={Style.chatSessionWrap} ref={contentsRef}>
        <div className={Style.headerWrapper}>
          <RecallButton
            chatType={ChatTypeEnum.WINWISE}
            type='text'
            icon='arrow_back_ios'
            size='small'
            label={t('go_back')}
            cssClass={Style.goBackBtn}
            onClick={goBack}
          />

          <h2>
            {opportunity?.clientName} - {opportunity?.name}
          </h2>

          <ChatSwitcher activeChat={ChatTypeEnum.WINWISE} />
        </div>

        {renderConversation()}
        {renderSuggestedQueries()}
        <QuestionBox
          cssClassName={Style.winwiseQuestionBox}
          createNewChatCallback={onSubmitNewChat}
          handleExpand={(value) => setIsExpanded(value)}
          expand={isExpanded}
        />
        <PreparingChatModal />
      </div>
    )
  }

  const onModalClose = async (hasOpportunityChange?: boolean) => {
    setShowManageFilesModal(false)

    if (!hasOpportunityChange) return

    if (hasFileChanges) {
      setIsUpdatingFiles(true)

      await updateOpportunityFiles({ opportunityId: opportunity?.id, nomicToken })
      setHasFileChanges(false)
      setIsUpdatingFiles(false)
    }

    await fetchOpportunityDetail()
  }

  const renderManageFilesPopup = () => {
    if (opportunity.isGeneratingAnswer || opportunity.dataState === GdpDataProcessingState.PROCESSING) {
      return (
        <NotificationModal
          open={showManageFilesModal}
          onClose={() => setShowManageFilesModal(false)}
          title={t('popup_toast3')}
          description='Files cannot be added or removed when processing or generating answers, please try again in 5 minutes.'
        />
      )
    }

    return (
      <WinwiseManageFilesModal
        open={showManageFilesModal}
        onClose={onModalClose}
        selectedOpportunity={opportunity}
        onFileChange={() => setHasFileChanges(true)}
        isOpportunityOwner={user?.sub === opportunity?.userId}
      />
    )
  }

  return (
    <div className={Style.opportunityThreadWrap}>
      {renderSidebar()}
      {renderChatSession()}
      {renderManageFilesPopup()}

      <Modal isShowing={isUpdatingFiles || isFetchingOpportunity} onClose={() => 0} size='small' isCloseButton={false}>
        <div className={Style.loaderContainer}>
          <Loader label={t('loading')} />
        </div>
      </Modal>
    </div>
  )
}

export default WinwiseOpportunityThread
