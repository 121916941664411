import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Icon, Loader, Pill, Tooltip, useToast } from '@aurecon-creative-technologies/styleguide'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { INewCapabilities, IQuestion } from '../models/IQuestionModels'
import AnswerActions from './AnswerActions'

import { appInsightsInstance } from '../api/AppInsights'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/ChatAnswer.module.sass'
import nextId from 'react-id-generator'
import { openLink } from '../helpers/utils'
import CapabilityModal from './modals/CapabilityModal'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { QuestionCapabilities, TriggerScroll } from '../stores/AppStore'
import { useSubmitButton } from '../hooks/useSubmitButton'
import classNames from 'classnames'
import RelatedQuestions from './RelatedQuestions'

const FIRST_ANSWER = 0
const CAPABILITY_TEXT_MAX_RANGE = 100
const PILL_REMOVED_BORDER = '1px solid red'
const PILL_ADDED_BORDER = '1px solid green'
const PILL_COLOUR_ADDED = 1
const PILL_COLOUR_DELETED = 16

interface IChatAnswerProps {
  question: IQuestion
  chatType?: number
}

const ChatAnswer: FC<IChatAnswerProps> = (props) => {
  const [selectedAnswer, setSelectedAnswer] = useState(FIRST_ANSWER)
  const { addToast } = useToast()
  const { question } = props
  const { t } = useLanguages()
  const [openCapModal, setOpenCapModal] = useState<boolean>(false)
  const setTriggerScroll = useSetRecoilState(TriggerScroll)

  const [selectedCapabilities, setSelectedCapabilities] = useRecoilState(QuestionCapabilities)

  const { handleQuestionSubmit } = useSubmitButton(props.chatType ?? 0)

  useEffect(() => {
    return () => {
      setSelectedCapabilities([])
    }
  }, [setSelectedCapabilities])

  useEffect(() => {
    setSelectedAnswer(question.answers.length - 1)
  }, [question, setSelectedAnswer])

  const answerRaw = question.answers[selectedAnswer]?.answer
  const answer = question.loading ? answerRaw || '*Thinking...*' : answerRaw || '*No answer found*'

  const handleSource = (link: string) => {
    if (!link) return
    const appInsights = appInsightsInstance()
    if (appInsights) appInsights.trackEvent({ name: 'Recall Response QuickLink', properties: { link } })

    openLink(link, true, true)
  }

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code)

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('popup_mess14'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const addCapabilities = (rowKey: string, capabilities: string[], questionInput: string) => {
    const otherCapabilities = selectedCapabilities.filter((c) => c.rowKey !== rowKey)
    const updatedCapabilities = [...otherCapabilities, { rowKey, capabilities, questionInput }]
    setSelectedCapabilities(updatedCapabilities)
  }

  const renderPill = (
    capability: string,
    colour: typeof PILL_COLOUR_ADDED | typeof PILL_COLOUR_DELETED,
    onClose?: () => void,
    border?: string,
    strikethrough?: boolean,
  ) => {
    const textClasses = classNames({
      [Style.longText]: true,
      [Style.strikeThrough]: strikethrough,
    })

    return (
      <Pill
        colour={colour}
        key={capability}
        size='large'
        cssClass={Style.capabilityPill}
        style={{ border }}
        onClose={onClose}
      >
        <span className={Style.capPillContent}>
          <Icon type='business_center' outlined={true} size='18px' className={Style.capIcon} />
          {capability.length > CAPABILITY_TEXT_MAX_RANGE ? (
            <Tooltip show={capability}>
              <span className={textClasses}>{capability}</span>
            </Tooltip>
          ) : (
            <span className={textClasses}>{capability}</span>
          )}
        </span>
      </Pill>
    )
  }

  const renderCapabilities = () => {
    return question.capabilities.map((capability) => {
      const deleted = currentSelectedCapabilites?.capabilities
        ? !currentSelectedCapabilites.capabilities.includes(capability)
        : false

      const colour = deleted ? PILL_COLOUR_DELETED : PILL_COLOUR_ADDED
      const border = deleted ? PILL_REMOVED_BORDER : undefined
      const onClose = !deleted ? () => removeCapabilities(capability) : undefined

      return renderPill(capability, colour, onClose, border, deleted)
    })
  }

  const currentSelectedCapabilites = useMemo(() => {
    const capability = selectedCapabilities.find((c) => c.rowKey === question.rowKey)
    return capability
  }, [question.rowKey, selectedCapabilities])

  const removeCapabilities = (capability: string) => {
    const capabilities = currentSelectedCapabilites?.capabilities || question.capabilities
    addCapabilities(question.rowKey, [...capabilities.filter((c) => c !== capability)], question.question)
  }

  const renderUpdatedCapabilities = () => {
    if (!currentSelectedCapabilites?.capabilities) return null

    const newAddedCapabilities = currentSelectedCapabilites.capabilities.filter(
      (c) => !question.capabilities.includes(c),
    )

    return newAddedCapabilities.map((capability) =>
      renderPill(capability, PILL_COLOUR_DELETED, () => removeCapabilities(capability), PILL_ADDED_BORDER),
    )
  }

  const onRegenerateClick = async () => {
    setTriggerScroll(true)

    const submitProps: INewCapabilities = currentSelectedCapabilites ?? {
      capabilities: question.capabilities,
      questionInput: question.question,
      rowKey: question.rowKey,
    }

    await handleQuestionSubmit(submitProps)
  }

  return (
    <div className={Style.answer}>
      <div className={Style.avatar}>
        {question.loading ? <Loader size='extra small' /> : <Icon type='smart_toy' outlined />}
      </div>
      <div className={Style.content}>
        {!!question.capabilities.length && (
          <div className={Style.quickLinks}>
            <div className={Style.label}>Capabilities</div>
            <div className={Style.links}>
              {renderCapabilities()}
              {renderUpdatedCapabilities()}
              <Button type='icon-round' icon='add' cssClass={Style.addButton} onClick={() => setOpenCapModal(true)} />
              <Button
                type='text'
                icon='autorenew'
                label='Regenerate'
                cssClass={Style.genButton}
                onClick={onRegenerateClick}
              />
            </div>
            <CapabilityModal
              open={openCapModal}
              onClose={() => setOpenCapModal(false)}
              pickedCapabilities={currentSelectedCapabilites?.capabilities || question.capabilities}
              onNextClicked={(capabilities) => addCapabilities(question.rowKey, capabilities, question.question)}
            />
          </div>
        )}
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            code(props) {
              const { children, className, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')

              if (!match)
                return (
                  <code {...rest} className={className}>
                    {children}
                  </code>
                )

              const code = String(children)

              return (
                <div className={Style.codeBlock}>
                  <SyntaxHighlighter {...rest} style={oneDark} language={match[1]} PreTag='div'>
                    {code.replace(/\n$/, '')}
                  </SyntaxHighlighter>
                  <Button
                    type='icon-square'
                    icon='content_copy'
                    size='small'
                    onClick={() => copyCode(code)}
                    cssClass={Style.copyCodeBtn}
                  />
                </div>
              )
            },
          }}
        >
          {answer}
        </ReactMarkdown>
        {!!question.sources.length && (
          <div className={Style.quickLinks}>
            <div className={Style.label}>Quick links</div>
            <div className={Style.links}>
              {question.sources.map((source) => {
                return (
                  <Button
                    key={nextId()}
                    icon='link'
                    type='secondary'
                    default
                    label={source.label}
                    cssClass={Style.linkButton}
                    onClick={() => handleSource(source.link)}
                    size='small'
                  />
                )
              })}
            </div>
          </div>
        )}
        <AnswerActions question={question} selectedAnswer={selectedAnswer} />
        {!!question.relatedQuestions.length && <RelatedQuestions question={question} />}
      </div>
    </div>
  )
}

export default ChatAnswer
