import { useSetRecoilState } from 'recoil'
import { ChatType, EnabledChat } from '../stores/AppStore'
import { useEffect } from 'react'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

export const useInitializeChatSession = (chatType: number) => {
  const setEnabledChat = useSetRecoilState(EnabledChat)
  const setChatType = useSetRecoilState(ChatType)

  useEffect(() => {
    setChatType(chatType)

    if (chatType !== ChatTypeEnum.WINWISE) setEnabledChat(true)

    return () => setChatType(null)
  }, [setChatType, chatType, setEnabledChat])
}
