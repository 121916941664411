import { appInsightsInstance } from '../api/AppInsights'
import { FC, useCallback, useEffect, useState } from 'react'
import Page from '../components/Page'
import { useNavigate, useParams } from 'react-router-dom'
import Style from '../styles/WinwiseOpportunity.module.sass'
import CreateNewThreadModal from '../components/modals/CreateNewThreadModal'
import { getWinwiseOpportunityDetail, triggerProcessFile } from '../api/winwiseService'
import { IWinwiseOpportunityDetailModel, IWinwiseOpportunityUploadedFile } from '../models/IWinwiseOpportunity'
import LoadingScreen from '../components/LoadingScreen'
import { AppRoute } from '../enums/AppRouteConstants'
import WinwiseOpportunityThread from '../components/winwise/WinwiseOpportunityThread'
import WinwiseOpportunityFileUpload from '../components/winwise/WinwiseOpportunityFileUpload'
import classnames from 'classnames'
import { useSetRecoilState } from 'recoil'
import { EnabledChat } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { useLanguages } from '../hooks/useLanguages'
import { useNomicToken } from '../hooks/useNomicToken'
import { GdpDataProcessingState } from '../enums/WinwiseOpportunityEnum'
import { useAuth0 } from '@auth0/auth0-react'

const WinwiseOpportunity: FC = () => {
  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'WinWise by clientSense Opportunity' })

  const { t } = useLanguages()
  const { nomicToken } = useNomicToken(ChatTypeEnum.WINWISE)

  const { opportunityId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [opportunity, setOpportunity] = useState<IWinwiseOpportunityDetailModel | null>(null)
  const [isShowingCreateNewThreadPopup, setIsShowingCreateNewThreadPopup] = useState(false)
  const [isOnThread, setIsOnThread] = useState(false)
  const setEnabledChat = useSetRecoilState(EnabledChat)
  const { user } = useAuth0()

  const fetchOpportunity = useCallback(async () => {
    if (!opportunityId) return
    setIsLoading(true)
    const response = await getWinwiseOpportunityDetail({ opportunityId: opportunityId ?? '' })
    const opportunity = response?.data ?? null

    setOpportunity(opportunity)
    setIsOnThread(!!opportunity?.dataState)
    setIsLoading(false)
    setEnabledChat(!opportunity?.archived)
  }, [opportunityId, setEnabledChat])

  useEffect(() => {
    fetchOpportunity()

    return () => setEnabledChat(true)
  }, [fetchOpportunity, setEnabledChat])

  const goBack = () => navigate(`/${AppRoute.WINWISE}`)

  const onUploadFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity: IWinwiseOpportunityDetailModel | null) => {
      if (!prevOpportunity) return null

      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: [...newFiles, file] }
    })
  }

  const onRemoveFileChange = (file: IWinwiseOpportunityUploadedFile) => {
    setOpportunity((prevOpportunity: IWinwiseOpportunityDetailModel | null) => {
      if (!prevOpportunity) return null

      const newFiles = prevOpportunity.uploadedFiles.filter((f) => f.id !== file.id)
      return { ...prevOpportunity, uploadedFiles: newFiles }
    })
  }

  const onCreateNewThread = () => {
    if (!opportunity) return

    const clonedOpportunity = {
      ...opportunity,
      dataState: opportunity.dataState ?? GdpDataProcessingState.PROCESSING,
    }
    setOpportunity(clonedOpportunity)
    !opportunity.dataState && triggerProcessFile({ opportunityId: opportunity?.id ?? '', nomicToken })

    setIsShowingCreateNewThreadPopup(false)
    setEnabledChat(true)
    setIsOnThread(true)
  }

  const onPreviousThreadOpen = () => {
    setIsOnThread(true)
    setIsShowingCreateNewThreadPopup(false)
  }

  const renderMainContent = () => {
    if (!opportunity) return <></>
    if (isOnThread)
      return (
        <WinwiseOpportunityThread
          opportunity={opportunity}
          goBack={goBack}
          onCreateNewThread={() => setIsShowingCreateNewThreadPopup(true)}
          onOpportunityChange={setOpportunity}
        />
      )
    return (
      <WinwiseOpportunityFileUpload
        opportunity={opportunity}
        goBack={goBack}
        onCreateNewThread={() => setIsShowingCreateNewThreadPopup(true)}
        onOpportunityChange={setOpportunity}
        onUploadFileChange={onUploadFileChange}
        onRemoveFileChange={onRemoveFileChange}
        readOnly={user?.sub !== opportunity?.userId}
      />
    )
  }

  const renderCreateNewThreadModal = () => {
    if (!isShowingCreateNewThreadPopup || opportunity?.archived) return <></>

    const threadsCount = opportunity?.threads?.length ?? 0
    const latestThread = opportunity?.threads[threadsCount ? threadsCount - 1 : threadsCount]

    return (
      <CreateNewThreadModal
        isShowing={isShowingCreateNewThreadPopup}
        opportunityId={opportunity?.id ?? ''}
        onClose={() => setIsShowingCreateNewThreadPopup(false)}
        onCreateThread={onCreateNewThread}
        latestThread={latestThread}
        onPreviousThreadOpen={onPreviousThreadOpen}
        isOnThread={isOnThread}
      />
    )
  }

  return (
    <Page
      menu
      contentWrapper
      contentWrapperWide
      cssClassName={classnames({ [Style.winwiseOpportunityWrapper]: isOnThread })}
    >
      {isLoading ? <LoadingScreen chatType={ChatTypeEnum.WINWISE} text={t('loading')} /> : renderMainContent()}
      {renderCreateNewThreadModal()}
    </Page>
  )
}

export default WinwiseOpportunity
