import { FC } from 'react'
import Style from '../../styles/modal/CreateNewThreadModal.module.sass'
import RecallButton from '../common/RecallButton'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallModal from '../common/RecallModal'
import { useSetRecoilState } from 'recoil'
import { ChatSession, InShareChat } from '../../stores/AppStore'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoute } from '../../enums/AppRouteConstants'
import { useLanguages } from '../../hooks/useLanguages'
import { IWinwiseOpportunityThreadModel } from '../../models/IWinwiseOpportunity'

interface ICreateNewThreadModalProps {
  isShowing: boolean
  opportunityId: string
  latestThread?: IWinwiseOpportunityThreadModel
  isOnThread: boolean
  onClose: () => void
  onCreateThread: () => void
  onPreviousThreadOpen: () => void
}

const CreateNewThreadModal: FC<ICreateNewThreadModalProps> = (props) => {
  const { isShowing, onClose, onCreateThread, onPreviousThreadOpen, opportunityId, latestThread, isOnThread } = props
  const { chatId } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguages()

  const setInShareChat = useSetRecoilState(InShareChat)
  const setChatSession = useSetRecoilState(ChatSession)

  const handleCreateNewThread = (inShare: boolean) => {
    setInShareChat(inShare)
    setChatSession(null)
    onCreateThread()

    if (chatId) {
      navigate(`/${AppRoute.WINWISE_THREAD}/${opportunityId}`, { replace: true })
    }
  }

  const handleOpenPreviousThread = () => {
    if (!latestThread?.id) return

    onPreviousThreadOpen()
    navigate(`/${AppRoute.WINWISE_THREAD}/${opportunityId}/${latestThread.id}`, { replace: true })
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.WINWISE}
      size='small'
      disabled={false}
      cssClass={Style.createNewThreadModal}
      isShowing={isShowing}
      showActionButtons={false}
      onClose={onClose}
    >
      {latestThread && !isOnThread && (
        <>
          <h2>{t('select_one')}</h2>
          <h4>{t('previous_thread')}</h4>
          <p>{t('previous_thread_desc')}</p>
          <div className={Style.actionButtons}>
            <RecallButton
              type='primary'
              chatType={ChatTypeEnum.WINWISE}
              label={t('previous_thread')}
              onClick={() => handleOpenPreviousThread()}
            />
          </div>
          <hr className={Style.divider} />
        </>
      )}
      <h4>{t('create_thread')}</h4>
      <p>{t('create_thread_desc')}</p>

      <div className={Style.actionButtons}>
        <RecallButton
          type='primary'
          chatType={ChatTypeEnum.WINWISE}
          label={t('share_thread')}
          onClick={() => handleCreateNewThread(true)}
        />
        <span className={Style.or}>{t('or')}</span>
        <RecallButton
          type='primary'
          chatType={ChatTypeEnum.WINWISE}
          label={t('personal_thread')}
          onClick={() => handleCreateNewThread(false)}
        />
      </div>
    </RecallModal>
  )
}

export default CreateNewThreadModal
