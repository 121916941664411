import { FC, useEffect, useRef, useState } from 'react'
import { Button, Tooltip, Icon, Loader } from '@aurecon-creative-technologies/styleguide'

import TextPreview from '../assets/txt_placeholder.png'
import FilePreview from '../assets/file_placeholder.png'
import { fileExtension, fileSize } from '../helpers/fileUtils'
import { PDF_EXT, TXT_EXT, IMAGE_EXT, OFFICE_EXT } from '../config/config'
import FilePreviewModal from './modals/FilePreviewModal'

import Style from '../styles/FilePreviewAttachmentFiles.module.sass'
import PDFViewer from './PDFViewer'
import { usefileDownload } from '../hooks/useFileDownload'
import { AppRoute } from '../enums/AppRouteConstants'
import { ChatType } from '../stores/AppStore'
import { useRecoilValue } from 'recoil'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

interface IFilePreviewAttachmentFilesProps {
  name: string
  blob: Blob | null
  fileType: string
  content: string | null
  isDeleted: boolean
  isLoading: boolean
  isAnswering: boolean
  action: 'download' | 'clear'
  disablePreview?: boolean
  onFileRemoved?: (fileName: string) => void
}

const FilePreviewAttachmentFiles: FC<IFilePreviewAttachmentFilesProps> = (props) => {
  const { downloadFile } = usefileDownload()
  const [preview, setPreview] = useState<string | null>(null)
  const [showImagePreview, setShowImagePreview] = useState<boolean>(false)
  const [size, setSize] = useState<string | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const [isPdf, setIsPdf] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const chatType = useRecoilValue(ChatType)
  const ref = useRef<HTMLDivElement>(null)
  const { name, content, blob, fileType, action, onFileRemoved, isDeleted, isLoading, isAnswering, disablePreview } =
    props

  useEffect(() => {
    if (!blob) return

    let data = ''
    const preview = () => {
      data = window.URL.createObjectURL(new File([blob], name, { type: fileType }))
      setSize(fileSize(blob.size))
      setUrl(data)

      const fileExt = fileExtension(name)

      if (IMAGE_EXT.some((ext) => ext === fileExt)) {
        setPreview(data)
        return
      }

      if (PDF_EXT.some((ext) => ext === fileExt)) {
        setPreview(data)
        setIsPdf(true)
        return
      }

      if (TXT_EXT.some((ext) => ext === fileExt)) {
        setPreview(TextPreview)
      }

      if (OFFICE_EXT.some((ext) => ext === fileExt)) {
        setPreview(FilePreview)
      }
    }

    preview()

    return () => {
      window.URL.revokeObjectURL(data)
    }
  }, [name, blob, fileType])

  const handleClick = () => {
    if (action === 'download' && blob) {
      downloadFile(blob, name)
      return
    }

    if (action === 'clear' && onFileRemoved) onFileRemoved(name)
  }

  const showPreview = () => {
    if (disablePreview) return
    setShowImagePreview(true)
  }

  const closePreview = () => {
    setShowImagePreview(false)
  }

  const handleFileNameHover = () => {
    const elm = ref.current
    if (!elm) return
    const tooltipActive = elm.scrollWidth > elm.offsetWidth
    setShowTooltip(tooltipActive)
  }

  const openPdf = () => {
    if (disablePreview) return
    localStorage.setItem(name, url ?? '')
    window.open(`#/${AppRoute.PDF_VIEWER}/${name}`, '_blank')
  }

  const renderFileWrapper = () => {
    if (chatType === ChatTypeEnum.WINWISE) return
    if (isDeleted || isAnswering)
      return (
        <div className={Style.fileDeletedWrapper}>
          {showTooltip ? (
            <Tooltip show={<div className={Style.fileNameTooltipLabel}>{name}</div>}>
              <div ref={ref} className={Style.fileDeletedName}>
                {name}
              </div>
            </Tooltip>
          ) : (
            <div
              ref={ref}
              className={Style.fileDeletedName}
              onMouseOver={() => {
                handleFileNameHover()
              }}
              onFocus={() => {
                handleFileNameHover()
              }}
              role='none'
            >
              {name}
            </div>
          )}

          {!isAnswering && (
            <div className={Style.fileDeletedIcon}>
              {!isLoading ? (
                <Tooltip show='Files are removed from Recall after 90 days'>
                  <Icon type='info' colour='#E10000' size='15px' />
                </Tooltip>
              ) : (
                <Loader size='extra small' label='' />
              )}
            </div>
          )}
        </div>
      )

    return (
      <div className={Style.imageInfo}>
        {!isPdf && (
          <img
            src={preview ?? FilePreview}
            alt={name}
            className={Style.filePreview}
            onClick={showPreview}
            role='none'
          />
        )}
        {isPdf && (
          <div className={Style.filePreview} onClick={openPdf} role='none'>
            <PDFViewer
              file={preview ?? ''}
              showOnlyAsPreview={true}
              viewerId={`viewer-${name}`}
              width={180}
              height={100}
            />
          </div>
        )}
        <div
          ref={ref}
          className={Style.imageData}
          onMouseOver={() => {
            handleFileNameHover()
          }}
          onFocus={() => {
            handleFileNameHover()
          }}
          role='none'
        >
          <div className={Style.name}>{name}</div>
          <div>
            <strong>{size}</strong>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div key={name} className={Style.fileItem}>
      {renderFileWrapper()}

      {!isDeleted && (
        <Button
          size='extra small'
          type='icon-round'
          icon={action}
          cssClass={Style.imagePreviewButton}
          default
          onClick={handleClick}
        />
      )}
      <FilePreviewModal show={showImagePreview} name={name} url={url} content={content ?? ''} onClose={closePreview} />
    </div>
  )
}

export default FilePreviewAttachmentFiles
