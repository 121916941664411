import { FC } from 'react'
import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallModal.module.sass'
import classNames from 'classnames'
import { useLanguages } from '../../hooks/useLanguages'

export interface IRecallModalProps {
  chatType: number
  isShowing: boolean
  disabled: boolean
  showActionButtons: boolean
  onClose: () => void
  onSave?: () => void
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  size?: 'small' | 'medium' | 'large'
  labelYes?: string
  labelNo?: string
  hideCloseIcon?: boolean
  loadingYes?: boolean
  hideYes?: boolean
  cssClass?: string
  hideNo?: boolean
}

const RecallModal: FC<IRecallModalProps> = (props) => {
  const { t } = useLanguages()

  const {
    chatType,
    isShowing,
    disabled,
    onSave,
    onClose,
    children,
    size,
    labelYes,
    labelNo,
    hideCloseIcon,
    loadingYes,
    hideYes,
    cssClass,
    showActionButtons,
    hideNo,
  } = props

  const chatTypeClasses = () => {
    return classNames({
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
      [Style.cra]: chatType === ChatTypeEnum.CUSTOM_RECALL_APP,
      [Style.default]: chatType === ChatTypeEnum.NONE,
      [Style.recall]: chatType === ChatTypeEnum.RECALL,
      [Style.winwise]: chatType === ChatTypeEnum.WINWISE,
    })
  }

  const secondaryButtonClasses = () => {
    return classNames(chatTypeClasses(), {
      [Style.actionButtons]: true,
      [Style.secondary]: true,
    })
  }

  const primaryButtonClasses = () => {
    return classNames(chatTypeClasses(), {
      [Style.actionButtons]: true,
      [Style.primary]: true,
    })
  }

  const actions = (): IButtonProps[] => {
    const buttons: IButtonProps[] = []

    if (!showActionButtons) return buttons

    const noButton: IButtonProps = {
      label: labelNo ?? t('cancel'),
      type: 'secondary',
      onClick: onClose,
      cssClass: secondaryButtonClasses(),
    }

    const yesButton = {
      label: labelYes ?? t('create'),
      onClick: onSave,
      disabled: disabled,
      cssClass: primaryButtonClasses(),
      loading: loadingYes,
    }

    if (!hideNo) buttons.push(noButton)
    if (!hideYes) buttons.push(yesButton)

    return buttons
  }

  const handleCloseModal = () => {
    onClose()
  }

  const classes = classNames({
    [Style.modalWrapper]: true,
    [cssClass ?? '']: !!cssClass,
  })

  return (
    <Modal
      cssClass={classes}
      isShowing={isShowing}
      onClose={handleCloseModal}
      isOverlay={true}
      shouldOverlayClose={false}
      actions={actions()}
      size={size}
      isCloseButton={!hideCloseIcon}
    >
      {children}
    </Modal>
  )
}

export default RecallModal
