import Joi from 'joi'

export const WinwiseOpportunityFields = {
  clientName: {
    fieldName: 'clientName',
    maxLength: 200,
  },
  name: {
    fieldName: 'name',
    maxLength: 500,
  },
}

export const winwiseOpportunityValidatorSchema = () => {
  return Joi.object({
    [WinwiseOpportunityFields.clientName.fieldName]: Joi.string()
      .trim()
      .min(1)
      .max(WinwiseOpportunityFields.clientName.maxLength)
      .required()
      .messages({
        'string.empty': 'Client Name is required',
        'string.max': `Client Name must be less than or equal to ${WinwiseOpportunityFields.clientName.maxLength} characters`,
      }),

    [WinwiseOpportunityFields.name.fieldName]: Joi.string()
      .trim()
      .min(1)
      .max(WinwiseOpportunityFields.name.maxLength)
      .required()
      .messages({
        'string.empty': 'Opportunity Name is required',
        'string.max': `Opportunity Name must be less than or equal to ${WinwiseOpportunityFields.name.maxLength} characters`,
      }),
  })
}
