import ReactDOM from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'

import config from './config/config'

import './styles/index.sass'

require('./helpers/react-fixes')

import './helpers/i18n'
import { MsalProvider } from '@azure/msal-react'
import { getMsalClientInstance } from './auth/msalClientInstance'
import { useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'

const onRedirectCallback = (appState?: AppState) => {
  window.location.href = appState?.targetUrl ? appState.targetUrl : '/'
}

const Main = () => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null)

  const getMainElement = (element: JSX.Element): JSX.Element => {
    return (
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation='localstorage'
        scope={config.AUTH0_SCOPE}
        audience={config.AUTH0_AUDIENCE}
      >
        {element}
      </Auth0Provider>
    )
  }

  useEffect(() => {
    const loadConfig = async () => {
      const instance = await getMsalClientInstance()

      setMsalInstance(instance)
    }

    loadConfig()
  }, [])

  if (!msalInstance) return getMainElement(<App />)

  return getMainElement(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>,
  )
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)
root.render(<Main />)
