import { FC, useEffect, useState } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/FocusButton.module.sass'

interface IExpandButtonProps {
  disabled?: boolean
  expand?: boolean
  handleExpand?: (isExpanded: boolean) => void
}

const ExpandButton: FC<IExpandButtonProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const { disabled, expand, handleExpand } = props

  useEffect(() => {
    setIsExpanded(expand ?? true)
  }, [expand])

  return (
    <Button
      type='icon-round'
      icon={isExpanded ? 'expand_more' : 'expand_less'}
      cssClass={Style.fileAttachButton}
      onClick={() => {
        setIsExpanded(!isExpanded)
        handleExpand?.(!isExpanded)
      }}
      disabled={disabled}
    />
  )
}

export default ExpandButton
